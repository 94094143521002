import {useMemo, useEffect, useState, createContext, useContext, useRef, useCallback } from 'react'

// import WalletConnectProvider from "@walletconnect/web3-provider";
import GetTxStorage, {InitSendStorage} from './transferLoca'


import {
    getBlock,
    // decimalHex,
    PROXY_ZERO_ADDRESS,
    DEFAULT_PRC,
    DEFAULT_CHAIN_ID,
    ZERO_ADDRESS,
    setDefaultProvider,
    setProvider,
    initWeb3,
    reInitWeb3,
    addMultiCall,
    SendOn,
    setConfirmation
} from 'web3-sam'

export * from 'web3-sam'

export const Context = createContext({})
setConfirmation(0)

// addMultiCall({
//     "223": "0xe8C20Ba23384586243B6a71f1425A7A04bE03D72"
// })

addMultiCall({
    "223": "0x6E25E2E9d89d1Ba697B912599490268Ec0ec0724",
    "31337": "0x55034b2cF530ae3A8fC1e2e4523F58496796610F",
    "813": "0x55034b2cF530ae3A8fC1e2e4523F58496796610F"
})

const CHANG_TYPE ={
    "1": "main",
    "2": "ropsten",
    "3": "rinkeby",
    "100": "xDai",
    "56": "bsc",
    "66": "okc",
    "65": "tOkc",
    "256": "tHeco",
    "128": "heco",
    "1337": "bsc",
    "31337": "fork",
    "1001": "tKlaytn",
    "8217": "klaytn",
    "137": "polygon",
    "80001": "tPolygon",
    "200": "xDai",
    "97": "tBsc",
    "223": "qng",
    "1111": "bsc",
    "42161": "arb",
    "813": "meer"
}



/**
创建 web3 为容器

链接 walletConnect： new walletConnect => provider => provider 添加到 web3
退出 walletConnect： on "disconnect" callback => setDefault provider => 添加到 web3

链接 metamask ：获取 web3 provider ，setProvider
退出 metamask ：监听 account => !account[0] === true => 设置 default provider

walletConnect 链接后 不会改变 监听 account
metamask 链接后 要监听 退出 change

chainId 监听不用改变

 */

const walletConnectRpc = {
    rpc: {
        1: "https://mainnet.mycustomnode.com",
        3: "https://ropsten.mycustomnode.com",
        100: "https://dai.poa.network",
        56: 'https://bsc-dataseed.binance.org',

        // ...
    },
}



function useWeb3Provider(defaultChian) {
    // 设置默认 provider 参数
    // const [defaultChainId, defaultAccount] = useMemo(() => {
    //     setDefaultProvider(defaultChian)
    //     // 这里的挂载可能会 导致 chainId 为 null
    //     // 初始化
    //     // if (window.ethereum) {
    //     //     setProvider(window.ethereum)
    //     // } else if (window.web3) {
    //     //     setProvider(window.web3.currentProvider)
    //     // }
    //     const web3 = initWeb3()
    //     // console.table(web3.currentProvider.selectedAddress, "web3.currentProvider.selectedAddress")

    //     return [
    //         // (defaultChian.chainId || DEFAULT_CHAIN_ID)*1,
    //         web3.currentProvider.chainId,
    //         web3.currentProvider.selectedAddress
    //     ]
    // },[defaultChian])

    // console.log(defaultAccount,"defaultAccount")
    
    // 初始打开的时候，账户默认为 设置的 默认地址
    // const [chainId, setId] = useState(defaultChainId*1)
    // const [account, setAccount] = useState(defaultAccount)

    const [chainInit, setChainInit] = useState(() => {
        setDefaultProvider(defaultChian)
        // 这里的挂载可能会 导致 chainId 为 null
        // 初始化
        // if (window.ethereum) {
        //     setProvider(window.ethereum)
        // } else if (window.web3) {
        //     setProvider(window.web3.currentProvider)
        // }
        const web3 = initWeb3()
        // console.table(web3.currentProvider.selectedAddress, "web3.currentProvider.selectedAddress")
        InitSendStorage(web3.currentProvider.chainId*1, web3.currentProvider.selectedAddress)
        return [
            // (defaultChian.chainId || DEFAULT_CHAIN_ID)*1,
            web3.currentProvider.chainId,
            web3.currentProvider.selectedAddress
        ]
    })
    const [chainId, account] = chainInit
    // 默认没有安装钱包
    const [connected, setConnected] = useState( null )

    // 默认没有解锁
    const unlock = useMemo(() => !!account && PROXY_ZERO_ADDRESS !== account  && ZERO_ADDRESS !== account,  [account])
    // 链简写
    // console.log(chainId, " chainId")
    const chainName = useMemo(() => CHANG_TYPE[chainId*1] || 'Net Error',  [chainId])

    // 检测当前链接钱包
    const setWalletName = useCallback(() => {
        const web3 = initWeb3()
        let walletName = null
        if ( web3.currentProvider.isBitKeep ) {
            walletName = 'bitKeep'
        }
        else if ( web3.currentProvider.isTokenPocket ) {
            walletName = 'tp'
        }
        else if ( web3.currentProvider.isImToken ) {
            walletName = 'imToken'
        }
        else if ( web3.currentProvider.isMetaMask ) {
            walletName = 'metamask'
        }
        else if ( web3.currentProvider.bridge ) {
            walletName = 'WalletConnect'
        }
        else if ( web3.currentProvider.isTrust ) {
            walletName = 'trust'
        }
        else if ( web3.currentProvider.isONTO ) {
            walletName = 'onto'
        }
        else if ( window.web3 ) {
            walletName = 'other'
        }
        // console.log({
        //     walletName
        // })
        setConnected(walletName)
        return walletName
    },[])

    // 设置 account
    const setAccountUse = useCallback(() => {
        const web3 = initWeb3()
        // console.log(web3.currentProvider, "web3.currentProvider.selectedAddress")
        Promise.all([
            web3.eth.getChainId(),
            web3.eth.getAccounts()
        ]).then(([id, [account]])=> {
            // 这里 id 已 rpc 为准
            if (id*1 !== web3.currentProvider.chainId*1) web3.currentProvider.chainId = id;
            // setId(id*1)

            // account = "0x0B46e83027eb0851241d0d28800F3e89e492f3A6"
            // account = "0x651d0419CAa08bf782591E02953325104Fb69228"
            // account = "0xF0314E18280C1519e7B5DBe1Ea7f09ae0D8cB9da"
            // account = "0x49d2F32ed9Cd5454a9aE5Abb30D62e3A09a9FF09"
            // account = "0x651d0419CAa08bf782591E02953325104Fb69228"

            // account = "0x0eb597e2c186B1D778DD15a62Ff41B7D39981e5b"
            // account = "0x285Bf17D7F7362061F464F0e7292b909934e2324"
            // account = "0xb0D9F5217325d68C379889e5D77C328E9A0CbfAa"
            // account = "0x7FBdcad4F23b1459F9949CC461A93F28Bb6975C3"
            // console.log(PROXY_ZERO_ADDRESS)
            reInitWeb3()
            setChainInit([id*1, account || PROXY_ZERO_ADDRESS])
            InitSendStorage(id*1, account || PROXY_ZERO_ADDRESS)
        })
    }, [])

    // 链接 metamask
    const connect = async () => {

        const web3 = reInitWeb3()
        // console.log(web3)
        if ( web3.currentProvider.enable ) {
            await web3.currentProvider.enable()
        } else {
            console.log('not install wallet')
        }
    }

    // 链接 wallet connect
    const connectWalletConnect = async () => {
        // try {
        //     const provider = new WalletConnectProvider(walletConnectRpc)
        //     await provider.enable();
        //     setProvider(provider)
        //     setWalletName()
        //     provider.on("disconnect", () => {
        //         // 重置
        //         reInitWeb3()
        //     });       
        // } catch (error) {
        //     console.log(
        //         error
        //     )
        // }
    }

    // 刷新链接
    useEffect(() => {
        // const provider = new WalletConnectProvider(walletConnectRpc)
        // if ( provider.walletMeta ) connectWalletConnect()
        // else setWalletName()
        setWalletName()
    },[defaultChian.selectedAddress, setWalletName])
    // 开启监听 account
    useEffect(() => {
        // const 
        setAccountUse()
        const inter = setInterval(async () => {
            try {
                setAccountUse()
            } catch (error) {}
        }, 1000);
        return () => clearInterval(inter)
    },[setAccountUse])
    
    return {
        chainName,
        account,
        connect,
        connected,
        unlock,
        chainId,
        connectWalletConnect
    }
}

export function useWeb3( ) {

    const provider = useContext(Context)
    const [, reLoad] = useState(true)

    const callRef = useRef(() => {
        // 这里会执行两次
        return getBlock.getNewBlock()
    })

    let getBlockNumber = () => {
        // 这里会执行两次
        return callRef.current()
    };

    // effect 是最后才执行的
    useEffect(() => {
        // 这里 只执行 1 次
        const oldCall = () => reLoad(v => !v)
        callRef.current = () => {
            getBlock.start(oldCall)
            return getBlock.getNewBlock()
        }
        // 激活
        oldCall()
        return () => {
            getBlock.remove(oldCall)
        }
    },[provider.chainId])
    // console.log(getBlock.getNewBlock(),'getBlock')
    return {
        ...provider,
        getBlockNumber
    }    
}

function Web3Provider({defaultChian = {} ,children }) {
    const {rpc=DEFAULT_PRC, selectedAddress=PROXY_ZERO_ADDRESS, chainId=DEFAULT_CHAIN_ID} = defaultChian
    const provider = useWeb3Provider({rpc, selectedAddress, chainId})
    // console.log(provider)
    return(
        <Context.Provider
            value={provider}
        >
            {children}
        </Context.Provider>
    )
}

export default Web3Provider




// send transaction and save to localstorage
// @params methods contract.methods
// @params pending pending callback
// @params confirm confirm callback
// @params options options
// @params signDone sign done callback
export function SendLocalOn(method, {cancel = () => {}, fail = () => {}, pending = () => {}, confirm = () => {}, signDone = () => {}, confirmDone = () => {}, cont = null,...options}) {
    const {
        createTx,
        confirmTx
    } = GetTxStorage();
    const {
        summary,
        getHash,
        confirmation
    } = SendOn(
        method,
        options
    )

    // console.log(
    //     {
    //         summary,
    //         getHash,
    //         confirmation
    //     }, " summary"
    // )
    // return
    
    // 交易状态
    // 1 交易被取消，未上链，没有 hash
    // 2 交易批准，已上链，交易失败 有 hash
    // 3 交易批准，已上链，交易成功 有 hash
    // 所以状态 在 confirm 里都可以使用
    // 但是 gētHash 会先于 confirm 执行，confirm 需要等待
    summary.cont = cont
    return getHash().then(res => {
        const [err, hash] = res
        signDone()
        // console.log(err, hash, "err, hash")
        // cancel transaction not save to localstorage
        if (err) {
            // 预执行 报错
            if (hash === -1) fail(err)
            else cancel(summary)
            return {
                conf: {},
                getHash: [
                    err,
                    hash
                ]
            }
        }
        // save to localstorage
        pending(summary)
        createTx({hash, to: summary.to, summary})

        const conf = confirmation().then(res => {
            const [confimErr, receipt] = res
            
            // receipt == null 时，没有 交易hash 无法执行 confirm
            if (confimErr) {
                fail(confimErr)
                confirmTx({hash, receipt: null, error: confimErr.message})
            } else {
                confirm(receipt)
                confirmTx({
                    hash,
                    receipt: {
                        blockNumber: receipt.blockNumber,
                        type: receipt.type,
                        status: receipt.status,
                        transactionIndex: receipt.transactionIndex
                    }
                })
            }
            confirmDone()
            return [confimErr, receipt]
        })
        return {
            conf,
            getHash: [
                err,
                hash
            ]
        }
    })
}


export async function updateTransaction(pendingMint = 480) {
    const web3 = initWeb3()
    const {
        getPendingTx,
        updateTx
    } = GetTxStorage()
    const txHashList = getPendingTx();
    const hashCalls = [];
    const hashDetail = [];

    // console.log(
    //     await web3.eth.getTransaction("0x620e9cdcab9ca168c6885d59497d899630f8f5a2c447b068ebadbbcc69d808df"),
    //     await web3.eth.getTransaction("0xaf732d8491f06baa2b3492eb543e43900a492b1e982311275339123e0125ce4b"),
    //     await web3.eth.getTransaction("0x16c73445d0b237f2f6bd9f0a9cb464ebc0c9862305823410795c327e0644183b"),
    //     await web3.eth.getTransaction("0xfd7261c67a2c3edf705863de6df1a94e7baec6f17cccd19acae6bf4777c8b59c"),
        
    // )
    const now = Date.now() / 1000
    txHashList.forEach((v, index) => {
        // 大于 8 分钟没有更新的交易 才去请求
        // console.log(now, v.creatTime, pendingMint, "now - v.creatTime")
        if ( now - v.creatTime > pendingMint*1 ) {
            hashCalls.push(web3.eth.getTransactionReceipt(v.hash))
            hashDetail.push(v);
        }
        // hashKeys[v.hash] = index;
        // hashCalls.push(web3.eth.getTransactionReceipt(v.hash))
    })
    const calls = await Promise.all(hashCalls)
    // console.log(hashDetail, "calls")
    // console.log(calls, "calls")
    // console.log(calls, "calls")
    // 如果全部交易 因为分叉没有上链 则 calls 返回一直为 空，交易状态就无法更新
    hashDetail.forEach((tx, i) => {
        const call = calls[i]
        // 超过 8 分钟 交易还没有上链 判定为 交易失败
        tx.receipt = {}
        if (!call) {
            tx.receipt.status = false
            tx.receipt.type = null
            tx.receipt.transactionIndex = null
            tx.receipt.blockNumber = null
        } else {
            tx.receipt.blockNumber = call.blockNumber
            tx.receipt.status = call.status
            tx.receipt.transactionIndex = call.transactionIndex
            tx.receipt.type = call.type 
        }
    })
    // calls.forEach((v, i) => {
    //     if (v) {
    //         const tx = txHashList[hashKeys[v.transactionHash]]
    //         if (!tx) return
    //         // console.log(tx, "tx", pendingMint)

    //         tx.receipt = {}
    //         tx.receipt.blockNumber = v.blockNumber
    //         tx.receipt.status = v.status
    //         tx.receipt.transactionIndex = v.transactionIndex
    //         tx.receipt.type = v.type 
    //     }
    // })
    updateTx()
} 