
import { useEffect } from 'react'
import styled from "styled-components";
// import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    TextGL,
    TextMD,
    TextSM,
    TextXL,
    WhiteSpace,
    FlexBlock,
    FlexBlockAuto,
    Block,
    Button,
    Hr,
    Container,
    Tooltip,
    Icon,
    TextM,
    TabStyled,
    HrCol,
    Hot,
    APR,
    TextLinear,
    Fragment,
    Polyline,
    Coin,
    TextP,
    Text,
    HrDotted,
    SquareDot,
    // CardBox,
    Input
} from "../../components"; 

import {
    useChooseBalance,
    useSearchToken
} from "../../hook/pages/useChooseToken"
import { wFormatInt } from 'web3-sam/dist/BigNumber';
import {utils, initWeb3} from 'web3-sam'

const { shortAddress } = utils

function DefaultList({disabledTokenAddress, selectedTokenAddress, onChoose = () => {}, addToken = () => {}}) {
    const choose = useChooseBalance()
    return (
        choose.list.map((v, i) => (
            <Card
                style={{marginBottom: "6px", opacity: v.address === disabledTokenAddress ? 0.5 : 1}}
                decorate={v.address === selectedTokenAddress ? "6" : "8"}
                key={v.address}
                onClick={() => {
                    if (v.address === disabledTokenAddress || v.address === selectedTokenAddress) return
                    onChoose([v.address, v.decimal, v.symbol, v.name])
                    addToken()
                }}
            >
                <FlexBlock flex>
                    <div>
                        <TextM>{v.symbol} <TextSM color="2">{v.name}</TextSM></TextM>
                        <br />
                        <TextSM color="2">{shortAddress(v.address, 8)}</TextSM>
                    </div>
                    <div>
                        <TextM>{wFormatInt(v.balance)} </TextM>
                        {
                            v.local &&
                            <RemoveToken
                                onClick={e => {
                                    // console.log(e)
                                    e.preventDefault()
                                    choose.removeToken(v.address)
                                }}
                                src="/remove.png"
                            />
                        }
                    </div>
                </FlexBlock>
            </Card>
        ))
    )
}

function ChooseToken({disabledTokenAddress, selectedTokenAddress, onChoose = () => {}}) {
    const {
        tokenInput,
        token,
        initToken,
        searchToken,
        addToken
    } = useSearchToken()

    

    const clean = () => tokenInput.onChange('')
    
    useEffect(() => {
        const web3 = initWeb3()
        if (!web3.utils.isAddress(tokenInput.value)) {
            initToken()
        } else {
            searchToken()
        }
    }, [tokenInput.value])

    return (
        <Card decorate='0'>
            <TextM>Select a Token</TextM>
            <WhiteSpace />
            <Input
                input={{
                    placeholder: 'Search Token Address',
                    ...tokenInput
                }}
                after={<Button size="0" status="1" onClick={clean}>Clean</Button>}
            />
            {
                token.address === false && <TextSM style={{color: "#da5217", marginTop: "4px", marginLeft: "12px"}}>Token Address Error</TextSM>
            }
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            {
                token.address ?
                <SearchBlock token={token}  {...{disabledTokenAddress, selectedTokenAddress, onChoose, addToken}}/>:
                <DefaultList {...{disabledTokenAddress, selectedTokenAddress, onChoose, addToken}}/>
            }
        </Card>
    )
}

export default ChooseToken


function SearchBlock({disabledTokenAddress, selectedTokenAddress, token = {}, onChoose = () => {}, addToken = () => {}}) {

    return (
        <Card
            style={{marginBottom: "6px", opacity: token.address === disabledTokenAddress ? 0.5 : 1}}
            decorate={token.address === selectedTokenAddress ? "6" : "8"}
            onClick={() => {
                if (token.address === disabledTokenAddress || token.address === selectedTokenAddress) return
                onChoose([token.address, token.decimal, token.symbol, token.name])
                addToken()
            }}
        >
            <FlexBlock flex>
                <div>
                    <TextM>{token.symbol} <TextSM color="2">{token.name}</TextSM></TextM>
                    <br />
                    <TextSM color="2">{shortAddress(token.address, 8)}</TextSM>
                </div>
                <TextM>{wFormatInt(token.balance)}</TextM>
            </FlexBlock>
        </Card>
    )
}

const RemoveToken = styled.img`
    width: 14px;
    margin-left: 6px;
    /* padding: 2px 8px; */
    /* margin: auto; */
    display: inline-block;
    vertical-align: baseline;
`