import React, { useMemo, useState, memo } from 'react'
import styled from "styled-components";
// import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    Text,
    TextMD,
    TextSM,
    TextXL,
    WhiteSpace,
    FlexBlock,
    FlexBlockAuto,
    Block,
    Button,
    Hr,
    Container,
    Tooltip,
    Icon,
    TextM,
    TabStyled,
    HrCol,
    Hot,
    APR,
    TextLinear,
    // CardBox,
    Input,
    Coin,
    NoData
} from "../../components";
// import { wFormatInt, BN, splitNumber } from '../../web3'
import {EtherToInt} from '../../hook/pages/bridge/ethers-sam'

// import useEthersProvider,{} from '../../hook/pages/bridge/useBridge'
// import EthersProvider,{useEthers} from '../../hook/pages/bridge/useEthers'
import {
    useBridgeOrder,
    useExchange,
    useOrderDetail
} from '../../hook/pages/bridge/useBridge'

import useDialog from '../../hook/useDialog'


// function EthersTest() {
//     const eth = useEthers()
//     const blockNubmer = eth.getBlockNumber()
//     return (
//         eth.unlock ?
//         <div>test eth {blockNubmer}</div>:
//         <div onClick={eth.connect}>connect wallet</div>
//     )
// }

const CHAIN_ICON = {
    56: "icon-celvexiaotubiao-02",
    813: "icon-a-bianzu2",
}
function Bridge() {
    const bridge = useBridgeOrder()
    const {
        button,
        inputAmount
    } = useExchange()
    const {open} = useDialog()
    const setMaxFrom = () => {}
    const handleSwitch = () => bridge.chooseFromChain(bridge.to.chainId)
    const disabledMin = inputAmount.value !== "" && inputAmount.value < bridge.from.tokenBalance.minInput
    const disabledMax = inputAmount.value !== "" && inputAmount.value > bridge.maxInput
    return (
        <Container>
            <CardBoxStake>
                <TextMD>Lens Bridge</TextMD>
                <br />
                <TextSM style={{ marginTop: '2px' }} color={2}>Easy way to Multiple Chain Exchange</TextSM>
                <WhiteSpace />
                <FlexBlock flex>
                    <TextM color='2' >From</TextM>
                    <TextSM color='2' onClick={setMaxFrom} >Balance: <TextLinear size='0'>{EtherToInt(bridge.from.tokenBalance.balance)}</TextLinear>
                        {/* <TextSM
                            style={{
                                backgroundColor: "#99d172",
                                color: "#000",
                                padding: "0px 2px",
                                marginLeft: '4px',
                                borderRadius: '2px'
                            }} b={800}>ALL</TextSM> */}
                    </TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Input
                    // square
                    before={<Icon style={{marginRight: "6px"}} size='24' type={CHAIN_ICON[bridge.from.chainId]} />}
                    after={<TextM>{bridge.from.tokenBalance.name}</TextM>}
                    input={inputAmount}
                />
                <WhiteSpace />
                <SwitchToken
                    onClick={handleSwitch}
                    src='/exchange.png'
                />
                <WhiteSpace />
                <FlexBlock flex>
                    <TextM color='2' >To</TextM>
                    <TextSM color='2' onClick={setMaxFrom} >Balance: <TextLinear size='0'>{EtherToInt(bridge.to.tokenBalance.balance)}</TextLinear></TextSM>
                </FlexBlock>
                <WhiteSpace />
                <Input
                    // square
                    before={<Icon style={{marginRight: "6px"}} size='24' type={CHAIN_ICON[bridge.to.chainId]} />}
                    after={<TextM>{bridge.to.tokenBalance.name}</TextM>}
                    // input={amountIn}
                    input={{
                        onlyread: true,
                        value: bridge.estimateOutput(inputAmount.value)
                    }}
                />
                <WhiteSpace />
                <FlexBlock flex>
                    <TextSM color='2'>Max Amount</TextSM>
                    <TextSM>{EtherToInt(bridge.maxInput)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>Min Amount</TextSM>
                    <TextSM>{EtherToInt(bridge.from.tokenBalance.minInput)}</TextSM>
                </FlexBlock>
                <FlexBlock flex>
                    <TextSM color='2'>Fee Rate</TextSM>
                    <TextSM>{bridge.from.tokenBalance.fee * 100}%</TextSM>
                </FlexBlock>
                <WhiteSpace />
                {
                    disabledMin && <TextSM style={{color:"red", margin: "0 0 6px 0"}} >Minimum {bridge.from.tokenBalance.minInput}</TextSM>
                }
                {
                    disabledMax && <TextSM style={{color:"red", margin: "0 0 6px 0"}} >Maxmun {bridge.maxInput}</TextSM>
                }
                <Button w='100' {...button} disabled={disabledMin || disabledMax} />
            </CardBoxStake>
            <WhiteSpace />
            <CardBoxStake>
                <TextM>Orders</TextM>
                {
                    bridge.orders.length === 0 ?
                    <NoData /> :
                    bridge.orders.map((o) => (
                        <PendingBlock key={`${o.chainId}_${o.oId}`} onClick={() => {
                            // getDetail(o.blockNumber, o.chainId)
                            open(
                                <TxDetail
                                    blockNumber={o.blockNumber}
                                    chainId={o.chainId}
                                    oId={o.oId}
                                />
                            )
                        }}>
                            <FlexBlock flex>
                                <FlexBlock flex justify='start' style={{flex: 3}}>
                                    <Icon size='30' type={CHAIN_ICON[o.chainId]} />
                                    <div style={{marginLeft: '12px'}}>
                                        <TextSM>{o.inputName}</TextSM>
                                        <br />
                                        <TextM color="2">{EtherToInt(o.inputAmount)}</TextM>
                                    </div>
                                </FlexBlock>
                                <SwitchToken w="36" src='/right-arrow.png' />
                                <FlexBlock flex justify='end' style={{flex: 3}}>
                                    <div style={{marginRight: '12px', textAlign: 'right'}}>
                                        <TextSM>{o.outputName}</TextSM>
                                        <br />
                                        <TextM color="2">{EtherToInt(o.outputAmount)}</TextM>
                                    </div>
                                    <Icon size='30' type={CHAIN_ICON[o.toChainId]} />
                                </FlexBlock>
                            </FlexBlock>
                            <PendingDetail close={o.withdraw}>
                                <TextSM color='1'>Confirm Block {o.confirmBlock}</TextSM>
                                <TextSM color='1'>{o.withdraw === null ? "pending":"Done"}</TextSM>
                            </PendingDetail>
                        </PendingBlock>
                    ))
                }
                
            </CardBoxStake>
        </Container>
    )
}

export default memo(function HomeV2 () {
    return (
        <>
            <SwapChoose
                value="Bridge"
                onChange={(target) => {
                    // console.log(target)
                    if ( target !== "Bridge" ) {
                        const location = window.location
                        if (location.hostname.split(".")[0] !== 'swap') {
                            // location.href = `https://bridge.1inch.exchange/`
                            // 替换二级域名 为 bridge
                            // location.hostname = location.hostname.replace(location.hostname.split(".")[0], "swap")
                            const hs = location.hostname.split(".")
                            let newHostname;
                            if (hs.length <= 1) {
                                newHostname = "swap." + location.hostname
                            }
                            else {
                                newHostname = location.hostname.replace(hs[0], "swap")
                            }
                            location.hostname = newHostname
                        }
                    }
                }}
            />
            <Bridge />
        </>
    )
})

function TxDetail({blockNumber, chainId, oId}) {
    const {
        detail,
        toExplorer,
        fromExplorer
    } = useOrderDetail(blockNumber, chainId, oId)
    return (
        <Card>
            <TextM>Hash Detail</TextM>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM>From Hash</TextSM>
                <div style={{display: "flex"}} onClick={fromExplorer}>
                    <TextSM
                        style={{
                            maxWidth: "140px",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                    >{detail.fromTxHash}</TextSM>
                    <SwitchToken w='28' src="share.png" />
                </div>
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM>To Hash</TextSM>
                <div style={{display: "flex"}} onClick={toExplorer}>
                    <TextSM
                        style={{
                            maxWidth: "140px",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}
                    >{detail.toTxHash}</TextSM>
                    <SwitchToken w='28' src="share.png" />
                </div>
            </FlexBlock>
        </Card>    
    )
}

const CardBoxStake = styled(Card)`
    overflow: initial;
    padding-top: 16px;
    padding-bottom: 24px;
`;

// const CoinName = styled(TextM)`
//     padding: 6px 12px;
//     border-radius: 10px;
//     background: rgb(6 6 6);
//     white-space: nowrap;
//     margin-left: 8px;
//     min-width: 80px;
//     text-align: center;
// `;

const SwitchToken = styled.img`
    width: ${p => p.w || 46}px;
    padding: 2px 8px;
    margin: auto;
    display: block;
`;

const PendingBlock = styled.div`
    padding: 6px 8px;
    border-radius: 10px;
    background: rgb(6 6 6);
    margin-top: 12px;
`;

const PendingDetail = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${p => p.close ? "#d6d6d6" : "linear-gradient(122deg, #FFD84F, #FFED8F)"};
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 6px;
`;

function SwapChoose({value = "Bridge", onChange = () => {}}) {
    const List = [
        "Trade",
        // "Liquidity",
        "Bridge"
    ]
    return (
        <Container align="left">
            {
                List.map(v => (
                    <Text
                        key={v}
                        style={{marginRight: "12px"}} onClick={() => onChange(v)} size={value === v ? "2" : "1"}
                        color={value === v ? "0" : "2"}
                    >{v}</Text>
                ))
            }
        </Container>
    )
}