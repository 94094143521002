
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import {
    Button,
    // FlexBlock,
    Icon,
    Card,
    // TextGL,
    TextMD,
    // TextM,
    TextSM,
    // TitleText,
    WhiteSpace,
    // WalletIcon,
    FlexBlock,
    CheckButton,
    TextLinear
} from '../../components'

import useDrawer from '../../hook/useDrawer'

import {useEthers} from '../../hook/pages/bridge/useEthers'

function shortAddress( address = '', len = 4 ) {
    return address.slice(0, len) +'...'+ address.slice(address.length - len)
}
// const {shortAddress} = utils

function ChooseWallet( ) {
    const { t } = useTranslation();
    const {connect} = useEthers()
    return (
        <Card
            b='4'
            pds={[3.2, 1.6, 5]}
        >
            <WhiteSpace size='3'/>
            <TextMD b={800}>{t("Connect Wallet")}</TextMD>
            <WhiteSpace size='3'/>
            <TextLinear size='1'>{t("Support Network")}</TextLinear>
            <WhiteSpace size='2' />
            <FlexBlock flex>
                <CheckButton status='1' style={{width:'60%'}} w='100' square >
                    <TextSM>{t("EVM")}</TextSM>
                </CheckButton>
            </FlexBlock>
            <WhiteSpace  size='2'/>
            <TextLinear size='1'>{t("Selected Wallet")}</TextLinear>
            <WhiteSpace size='2' />
            <FlexBlock flex>
                <Card
                    onClick={connect}
                    decorate={8}
                    onLogo
                    align='center'
                    w='48'

                >
                    <Icon size='30' type='icon-metamask'/>
                    <WhiteSpace />
                    <TextSM>MetaMask</TextSM>
                </Card>
                {/* <Card
                    onClick={connectWalletConnect}
                    decorate={8}
                    onLogo
                    align='center'
                    w='48'
                >
                    <Icon size='30' type='icon-WalletConnect'/>
                    <WhiteSpace />
                    <TextSM>WalletConnect</TextSM>
                </Card> */}
            </FlexBlock>
            
        </Card>
    )
}

const CHAIN_NAME = {
    56: 'bsc',
    813: 'qnmeerg'
}
export const useConnectWallet = () => {
    const {open, close} = useDrawer(<ChooseWallet/>, {closeButton: true})
    const { unlock, chainId} = useEthers()
    const chainName = CHAIN_NAME[chainId]
    // const short = useMemo(() => shortAddress(account), [account])
    useEffect(() => {
        if ( unlock ) close()
    },[unlock, close])
    return {
        open,
        close,
        unlock,
        chainName
    }
}


const netIcon = {bsc: 'icon-bian', qng: 'icon-a-bianzu2'}
function ConnectWallet( ) {
    const {unlock, chainName, open} = useConnectWallet()
    // console.log(netIcon[chainName])
    return (
        <Button square status='4' onClick={() => { if( !unlock ) open()}}>
            {/* {short} */}
            <Icon type={netIcon[chainName] && unlock?(netIcon[chainName] || 'icon-jinzhi--xian'):'icon-jinzhi--xian'} />
            {/* <HashAddress>
                <WalletIcon name={connected} />
            </HashAddress> */}
        </Button>
    )
}

export default ConnectWallet


export function Address() {
    const { t } = useTranslation();
    // const { open } = useToast()
    const { open: openNet } = useConnectWallet()
    const { account, unlock } = useEthers()
    const short = useMemo(() => unlock ? shortAddress(account, 3) : t('no address'), [unlock, account, t])
    
    return (
        !unlock ?
            <AddressButton
                size='0'
                status='0'
                onClick={openNet}
            >
                <TextSM color='1'>{t("Connect")}</TextSM>
            </AddressButton>
            :
            <AddressButton
                size='3'
                status='0'
            >
                <TextSM color='1'>
                    {short}
                </TextSM>
            </AddressButton>
    )
}


const AddressButton = styled(Button)`
    padding: .5rem 1.4rem !important;
    border-radius: 100px !important;
    /* border: 1px solid #ff9a32; */
    &.MuiButton-root:hover {
        background: none;
    }
    /* color: #ff9a32; */
`

const Tips = styled.span`
    padding: 0px 4px;
    border-radius: 10px;
    background: #d75a2f;
    color: #fff;
`