import React, { useMemo, useState } from 'react'
import styled from "styled-components";
// import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    TextGL,
    TextMD,
    TextSM,
    TextXL,
    WhiteSpace,
    FlexBlock,
    FlexBlockAuto,
    Block,
    Button,
    Hr,
    Container,
    Tooltip,
    Icon,
    TextM,
    TabStyled,
    HrCol,
    Hot,
    APR,
    TextLinear,
    // CardBox,
    Input
} from "../../components";
import { wFormatInt, BN, splitNumber } from '../../web3'

import { useSlippage, useDialog1 } from '../../hook'

import {useGetPair, useSwap} from '../../hook/pages/useSwap';
import Approve from '../../contractComponents/Approve';

import ChooseToken from '../ChooseToken'


// import useMirrorBalance from '../../hook/pages/useMirrorBalance'

// const useStyles = makeStyles(() => ({
//     inputWrap: {
//         backgroundColor: '#fff !important',
//         color: '#000'
//     },
//     ml3: {
//         marginLeft: '3px'
//     },
//     ml5: {
//         marginLeft: '5px'
//     },
//     mt5: {
//         marginTop: '5px'
//     }
// }));

// const ItemWrap = ({ coin = {}, ...other }) => {
//     const classes = useStyles();
//     //  ['0x2bC86CF8b0D723934d5a48789e2AEE1212039673', 18, 'MIS']
//     // console.log('coin[2]--', coin)
//     return (
//         <FlexBlockAuto flex {...other}>
//             <Coin size='32' type={coin[2]} />
//             <FlexBlockAuto className={classes.ml3} flex justify="start">
//                 <TextM>{coin[2]}</TextM>
//                 <Icon className={classes.ml5} color='#fff' type='icon-arrow-down' />
//             </FlexBlockAuto>
//         </FlexBlockAuto>
//     )
// }

function Slippage() {
    const { open } = useSlippage()
    return (
        <Icon color='#9CA3AF' onClick={open} type='icon-setting' />
    )
}

function usePriceRer() {
    const [re, setRe] = useState(false)
    const reverse = () => setRe(v => !v)
    return [re, reverse]
}
function Price({ fromName, toName, fromValue, toValue }) {
    const [isPriceRev, priceRev] = usePriceRer()
    if (isPriceRev) {
        [toName, fromName] = [fromName, toName];
        [toValue, fromValue] = [fromValue, toValue];
    }
    const sp = splitNumber(fromValue / toValue, 4)
    return (
        <FlexBlockAuto onClick={priceRev}>
            <TextSM
                
            >
                {toValue*1 <= 0 ? 0 : `${sp.integer}.${sp.decimal0 !== 0 ? "[" + sp.decimal0 +"]" : ''}${sp.decimalTail.slice(0,4)}`} {fromName} / {toName}
            </TextSM>
            <ChangeToken src='/exchangesmall.png' />
        </FlexBlockAuto>
    )
}
export default function SwapTokenView() {
    const {
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        amountOutMin,
        switchRes: handleSwitch,
        // resetToken,
        slippage,
        tokenInBalance,
        tokenOutBalance,
        approve,
        status,
        setTokenIn,
        setTokenOut,
        smartPath
    } = useSwap()

    const { open, close } = useDialog1()
    const openChooseFrom = () => {
        open(
            <ChooseToken
                disabledTokenAddress={tokenOut[0]}
                selectedTokenAddress={tokenIn[0]}
                onChoose={token => {
                    setTokenIn(token)
                    close()
                }}
            />
        )
    }

    const openChooseTo = () => {
        open(
            <ChooseToken
                disabledTokenAddress={tokenIn[0]}
                selectedTokenAddress={tokenOut[0]}
                onChoose={token => {
                    setTokenOut(token)
                    close()
                }}
            />
        )
    }

    const setMaxFrom = () => {
        amountIn.onChange(tokenInBalance)
    }
    return (
        <Container>
            <CardBoxStake flex decorate='0'>
                <Block>
                    <FlexBlock flex>
                        <div>
                            <TextMD>{process.env.TITLE} Swap</TextMD>
                            <br />
                            <TextSM style={{ marginTop: '2px' }} color={2}>Easy way to trade your tokens</TextSM>
                        </div>
                        <Slippage />
                    </FlexBlock>
                    <WhiteSpace size='2' />
                    <FlexBlock flex>
                        <TextM color='2' >From</TextM>
                        <TextSM color='2' onClick={setMaxFrom} >Balance: <TextLinear size='0'>{wFormatInt(tokenInBalance)}</TextLinear>
                            <TextSM
                                style={{
                                    backgroundColor: "#99d172",
                                    color: "#000",
                                    padding: "0px 2px",
                                    marginLeft: '4px',
                                    borderRadius: '2px'
                                }} b={800}>ALL</TextSM>
                        </TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <Input
                        // square
                        // before={<Coin size='32' type={tokenIn[2]} />}
                        input={amountIn}
                        after={<CoinName onClick={openChooseFrom}>{tokenIn[2]}</CoinName>}
                    />
                    <WhiteSpace />
                    <Block center my="10px" >
                        <SwitchToken
                            onClick={handleSwitch}
                            src='/exchange.png'
                        />
                        {/* <Icon
                            color='#fff'
                            onClick={handleSwitch}
                            type='icon-swap-svgrepo-com'
                        /> */}
                    </Block>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextM color='2' >To</TextM>
                        <TextSM color='2'>Balance: <TextLinear size='0'>{wFormatInt(tokenOutBalance)}</TextLinear></TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <Input
                        // square
                        // before={<Coin size='32' type={tokenOut[2]} />}
                        input={{
                            value: amountOut,
                            onlyread: true
                        }}
                        after={<CoinName onClick={openChooseTo}>{tokenOut[2]}</CoinName>}
                    />
                    <WhiteSpace size={1} />
                    <FlexBlock flex>
                        <Tooltip
                            title={
                                <TextSM style={{lineHeight: "16px"}}>Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.</TextSM>
                            }
                            placement="top"
                            arrow
                        >
                            <TextSM color='2'>Minimum <Icon style={{ color: '#fff' }} size='12' type='icon-prompt' /></TextSM>
                        </Tooltip>
                        <TextSM>{amountOutMin} {tokenOut[2]}</TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>Cost Price </TextSM>
                        <Price
                            fromName={tokenIn[2]}
                            toName={tokenOut[2]}
                            fromValue={amountIn.value}
                            toValue={amountOutMin}
                        />
                    </FlexBlock>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>Slippage tolerance</TextSM>
                        <TextSM>{slippage} %</TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>Smart Path</TextSM>
                        <TextSM>{smartPath}</TextSM>
                    </FlexBlock>
                    {/* <WhiteSpace />
                    <FlexBlock flex>
                        <Tooltip
                            title={
                                <TextSM>The difference between the market price and estimated price due to trade size.</TextSM>
                            }
                            placement="top"
                            arrow
                        >
                            <TextSM color='2'>Price Impact <Icon style={{ color: '#fff' }} size='12' type='icon-prompt' /></TextSM>
                        </Tooltip>
                        <TextSM>{P1} | {wFormatInt(priceImpact)} %</TextSM>
                    </FlexBlock> */}
                    <WhiteSpace size={2} />
                    <Approve
                        w='100'
                        size='1'
                        status='1'
                        {...approve}
                        loading={status.loading}
                    />
                </Block>
            </CardBoxStake>
        </Container>
    )
}
// const CoinCellWap = styled(Block)`
//     // background: #F9FAFB;
//     // border: 0.6px solid #E5E7EB;
//     border-radius: 12px;
//     padding:1rem 0rem;
// `;

const CardBoxStake = styled(Card)`
    overflow: initial;
    padding-top: 16px;
    padding-bottom: 24px;
`;

// const FlexBlockList = styled(FlexBlock)`
//     padding: 1rem 0;
// `;

const CoinName = styled(TextM)`
    padding: 4px 8px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.15);
    white-space: nowrap;
`

const SwitchToken = styled.img`
    width: 46px;
    padding: 2px 8px;
    margin: auto;
    display: block;
`

const ChangeToken = styled.img`
    width: 14px;
    margin-left: 4px;
    /* padding: 2px 8px; */
    /* margin: auto; */
    display: inline-block;
    vertical-align: sub;
`