import React, { useMemo, useState } from 'react'
import styled from "styled-components";
// import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    TextGL,
    TextMD,
    TextSM,
    WhiteSpace,
    FlexBlock,
    Block,
    Button,
    Hr,
    Container,
    Icon,
    TextM,
    TextLinear,
    // CardBox,
    Input
} from "../../components";
import { wFormatInt, BN, splitNumber } from '../../web3'

import { useSlippage, useDialog1 } from '../../hook'

import {
    useLiquidity,
    useAddLiquidity,
    useRemoveLiquidity
    // useSwap
} from '../../hook/pages/useSwap';
import Approve from '../../contractComponents/Approve';

import ChooseToken from '../ChooseToken'


function Slippage() {
    const { open } = useSlippage()
    return (
        <Icon color='#9CA3AF' onClick={open} type='icon-setting' />
    )
}

export default function SwapTokenView() {
    const lp = useLiquidity()
    const token0 = lp.liquidity.token0
    const token1 = lp.liquidity.token1
    // const pair = lp.liquidity.pair
    
    const {
        amount0,
        amount1,
        setAmount0,
        setAmount1,
        shareOfPool,
        addLiquidityButton
    } = useAddLiquidity(lp.liquidity)

    const {
        lpAmount,
        removeLiquidityButton,
        lp0,
        lp1
    } = useRemoveLiquidity(lp.liquidity)

    const { open, close } = useDialog1()
    const openChoose0 = () => {
        open(
            <ChooseToken
                disabledTokenAddress={token1.detail[0]}
                selectedTokenAddress={token0.detail[0]}
                onChoose={token => {
                    lp.setToken0(token)
                    close()
                }}
            />
        )
    }

    const openChoose1 = () => {
        open(
            <ChooseToken
                disabledTokenAddress={token0.detail[0]}
                selectedTokenAddress={token1.detail[0]}
                onChoose={token => {
                    lp.setToken1(token)
                    close()
                }}
            />
        )
    }

    return (
        <Container>
            <CardBoxStake flex decorate='0'>
                <Block>
                    <FlexBlock flex>
                        <div>
                            <TextMD>Organize Liquidity</TextMD>
                            <br />
                            <TextSM style={{ marginTop: '2px' }} color={2}>Easy way to Organize your Lp</TextSM>
                        </div>
                        <Slippage />
                    </FlexBlock>
                    <WhiteSpace size='2' />
                    {/* choose tokens */}
                    <FlexBlock flex style={{marginBottom: "6px"}}>
                        <TextSM color="0" style={{flex:4}}>Choose Token Pair</TextSM>
                        <TextSM color="0" style={{flex: 2, textAlign: "right"}}>Swap</TextSM>
                    </FlexBlock>
                    <FlexBlock flex>
                        <div style={{flex:5}}>
                            <CoinName onClick={openChoose0}>{token0.detail[2]}</CoinName>
                            <TextM style={{margin: "0px 6px"}}>+</TextM>
                            <CoinName onClick={openChoose1}>{token1.detail[2]}</CoinName>
                        </div>
                        <CoinName style={{flex: 1}}>LSwap</CoinName>
                    </FlexBlock>
                    <WhiteSpace size='1'/>
                    <TextSM style={{marginBottom: "6px"}}>Remove Liquidity</TextSM>
                    <BlockChoose>
                        <FlexBlock flex>
                            <div>
                                <TextSM color="2">Pair Liquidity Balance</TextSM>
                                <br />
                                <Input
                                    style={{
                                        padding: "4px 4px 4px 0px",
                                    }}
                                    input={{
                                        ...lpAmount,
                                        placeholder:"0.00"
                                    }}
                                    after={
                                        <TextSM
                                            // onClick={setMaxFrom}
                                            style={{
                                                backgroundColor: "#99d172",
                                                color: "#000",
                                                padding: "2px 6px",
                                                marginLeft: '4px',
                                                borderRadius: '8px'
                                            }}
                                            b={800}
                                        >ALL</TextSM>
                                    }
                                />
                                <TextSM color="2">{wFormatInt(lp0)} {token0.detail[2]} + {wFormatInt(lp1)} {token1.detail[2]}</TextSM>
                            </div>
                            <Approve size="0" {...removeLiquidityButton} />
                        </FlexBlock>
                    </BlockChoose>
                    <WhiteSpace size='1'/>
                    <TextSM style={{marginBottom: "6px"}}>Add Liquidity</TextSM>
                    <BlockChoose style={{display: "block"}}>
                        <FlexBlock flex>
                            <CoinNameSmall>{token0.detail[2]}</CoinNameSmall>
                            <TextSM color='2'>
                                Balance: <TextLinear size='0'>{wFormatInt(token0.balance)}</TextLinear>
                            </TextSM>
                        </FlexBlock>
                        <Input
                            style={{
                                padding: "4px 0px",
                            }}
                            // square
                            // before={<Coin size='32' type={tokenIn[2]} />}
                            input={{
                                value: amount0.value,
                                onChange: e => setAmount1(e.target.value),
                                placeholder: "0.00"
                            }}
                            after={
                                <TextSM
                                    onClick={() => {
                                        setAmount0(token0.balance)
                                    }}
                                    style={{
                                        backgroundColor: "#99d172",
                                        color: "#000",
                                        padding: "2px 6px",
                                        marginLeft: '4px',
                                        borderRadius: '8px'
                                    }}
                                    b={800}
                                >ALL</TextSM>
                            }
                        />
                        <WhiteSpace />
                        <Hr />
                        <WhiteSpace />
                        <FlexBlock flex>
                            <CoinNameSmall>{token1.detail[2]}</CoinNameSmall>
                            <TextSM color='2'>
                                Balance: <TextLinear size='0'>{wFormatInt(token1.balance)}</TextLinear>
                            </TextSM>
                        </FlexBlock>
                        <Input
                            style={{
                                padding: "4px 0px",
                            }}
                            // square
                            // before={<Coin size='32' type={tokenIn[2]} />}
                            input={{
                                value: amount1.value,
                                onChange: e => setAmount0(e.target.value),
                                placeholder: "0.00"
                            }}
                            after={
                                <TextSM
                                    onClick={() => {
                                        setAmount1(token1.balance)
                                    }}
                                    style={{
                                        backgroundColor: "#99d172",
                                        color: "#000",
                                        padding: "2px 6px",
                                        marginLeft: '4px',
                                        borderRadius: '8px'
                                    }}
                                    b={800}
                                >ALL</TextSM>
                            }
                        />
                    </BlockChoose>
                    <WhiteSpace />
                    <FlexBlock flex>
                        <TextSM color='2'>{token0.detail[2]} per {token1.detail[2]}</TextSM>
                        <TextSM>{amount1.value*1 === 0 ? 0 : wFormatInt(amount0.value / amount1.value)}</TextSM>
                    </FlexBlock>
                    <FlexBlock flex>
                        <TextSM color='2'>{token0.detail[2]} per {token1.detail[2]}</TextSM>
                        <TextSM>{amount0.value*1 === 0 ? 0 : wFormatInt(amount1.value / amount0.value)}</TextSM>
                    </FlexBlock>
                    <FlexBlock flex>
                        <TextSM color='2'>Share of Pool</TextSM>
                        <TextSM color="2"><TextSM>{shareOfPool}</TextSM> %</TextSM>
                    </FlexBlock>
                    <WhiteSpace />
                    <Approve
                        w='100'
                        size='1'
                        status='1'
                        {...addLiquidityButton}
                        loading={lp.loading}
                    />
                </Block>
            </CardBoxStake>
        </Container>
    )
}
// const CoinCellWap = styled(Block)`
//     // background: #F9FAFB;
//     // border: 0.6px solid #E5E7EB;
//     border-radius: 12px;
//     padding:1rem 0rem;
// `;

const CardBoxStake = styled(Card)`
    overflow: initial;
    padding-top: 16px;
    padding-bottom: 24px;
`;

// const FlexBlockList = styled(FlexBlock)`
//     padding: 1rem 0;
// `;

const CoinName = styled(TextM)`
    padding: 4px 16px;
    border-radius: 10px;
    background: rgba(7,7,7);
    white-space: nowrap;
`

const CoinNameSmall = styled(TextSM)`
    padding: 4px 22px 4px 0px;
    white-space: nowrap;
    /* border-radius: 100px; */
    /* background: rgba(255,255,255, .4); */
`

// const ChangeToken = styled.img`
//     width: 14px;
//     margin-left: 4px;
//     /* padding: 2px 8px; */
//     /* margin: auto; */
//     display: inline-block;
//     vertical-align: sub;
// `

const BlockChoose = styled(FlexBlock)`
    background-color: rgba(7,7,7);
    padding: 12px;
    border-radius: 12px;
`