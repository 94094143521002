import { useCallback } from 'react'

import {Ready} from '../../components'

import {useImport} from '../../hook'

import {initConstants} from '../../contract/constants'
import {useWeb3} from '../../web3'
import { useEffect } from 'react'

import UserProvider from '../../context/UserProvider'
import NetError from '../NetError'

import EthersProvider from '../../hook/pages/bridge/useEthers'


function Provider({children}) {
    return (
        <UserProvider>
            {children}
        </UserProvider>
    )
}

function InitConstants({children}) {
    const {chainName} = useWeb3('InitConstants')
    // console.log(chainName,'chainName')
    const init = useCallback(() => initConstants(chainName), [chainName])
    const {ready, error, onReady} = useImport(init, {})
    useEffect(()=> {
        onReady()
    },[chainName])
    // ready || error
    // console.log(ready,error,'ready,error')
    return (
        <Ready ready={ready || error}>
        {/* <Ready ready={true}> */}
            {
                error?
                <NetError />:
                <Provider>{children}</Provider>
            }
        </Ready>
    )
}

export default InitConstants

export function NoReload({children}) {
    const {chainName} = useWeb3('InitConstants')
    // console.log(chainName,'chainName')
    const init = useCallback(() => initConstants(chainName), [chainName])
    const {ready, error, onReady} = useImport(init, {})
    useEffect(()=> {
        onReady()
    },[chainName])
    // ready || error
    // console.log(ready,error,'ready,error')
    return (
        error?
        <NetError />:
        <EthersProvider
            chainId={56}
            rpc="https://binance.llamarpc.com"
            // rpc="https://bsc-dataseed3.binance.org"
        >
            {children}
        </EthersProvider>
    )
}