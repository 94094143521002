
import { useEffect } from 'react'

import {
    TextGL
} from "../../components"; 
import {useHashDetail} from '../../hook/pages/useCheckHash'

function CheckHash() {
    const {
        loading,
        hashDetail,
        getDetail
    } = useHashDetail()

    console.log(hashDetail)
    return (
        <div onClick={() => getDetail("0x39214b09d1a4e82d1654dfc0dfe065540798a06dadf93ca5d4b8575aca0ad208")}>
            <TextGL>Check Hash</TextGL>
        </div>
    )
}

export default CheckHash