import {useContext, useEffect, createContext, useState} from 'react';

import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import {
    // HashRouter as Router,
    Route,
    Link,
    useHistory
} from "react-router-dom";

// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import {
    Accordion,
    Icon,
    // TextMD,
    TextM,
    Container,
    HrDotted,
    Hr,
    WhiteSpace,
    TextSM,
    FlexBlock,
    Card,
    Button
} from '../components'

// import useDrawer from '../hook/useDrawer'

// import XUsdtFarm from './XUsdtFarm'
// import Borrow from './Borrow'
// import Dashboard from './Dashboard'
// import Liquidation from './Liquidation'
import { Logo } from '../pageComponents';
import Home from './Home'
import CheckHash from './CheckHash'

// import Compensation from './Compensation'

// import UAdmin from './MUAdmin'


// import Swap from './Swap'
import Theme from './Theme'

// import SS from './Compensation/_index.js'


// import Footer from './Footer'

// // import Me from './Me'
// import {RelationPage} from './Relation'

// import Pools from './Pools'
// import Node from './Node'
// import Swap from './Swap'

// import ChooseToken from './ChooseToken'

// import Govern from './Govern'
// import SuperDao from './SuperDao'
// import StakeSpd from './StakeSpd'

// import NodeSpd from './Node/NodeSpd'


// import {useRelationData} from '../hook/pages/useRelation'
import { useWeb3, utils } from '../web3'
const { shortAddress } = utils

// import { useState } from 'react';
// import Account from './Account'
// import Relation from './Relation'

// import Energy from './Energy'
// Home
export const Done = [
    {
        title: 'Home',
        path: '/',
        exact: true,
        component: Home
    }
    
    // { 
    //     title: 'Swap',
    //     path: '/swap',
    //     exact: true,
    //     component: Swap
    // },
    // {
    //     title: 'Compensation Pool',
    //     path: '/compensation',
    //     exact: true,
    //     component: Compensation
    // },
    // {
    //     title: 'Govern MDAO',
    //     path: '/govern',
    //     exact: true,
    //     component: Govern
    // },
    
]

export const Dev = [
    
    // {
    //     title: 'Swap',
    //     path: '/network',
    //     exact: true,
    //     // href: 'https://dapp.xpslab.pro/',
    //     component: null,
    //     disabled: true
    // }
]

// 隐形路由
export const INVISIBLE = [
    {
        title: 'Theme',
        path: '/theme',
        exact: true,
        component: Theme
    },
    {
        title: 'Hash',
        path: '/hash',
        exact: true,
        component: CheckHash
    }
    // {
    //     title: 'uAdmin',
    //     path: '/uadmin',
    //     exact: true,
    //     component: UAdmin
    // },
    // {
    //     title: 'tokens',
    //     path: '/tokens',
    //     exact: true,
    //     component: ChooseToken
    // }
    
]

export const RouterFlat = (() => {
    const flat = [];
    [...Done, ...Dev,...INVISIBLE].map(v => {
        const {child, ...other} = v
        flat.push(other)
        if ( child ) {
            child.map(v => flat.push(v))
        }
        return v
    })
    return flat
})();

/////// router list ///////
function RouterList() {
    const history = useHistory()
    const [paths, setPath] = useState(() => history.location.pathname)
    useEffect(() => {
        history.listen(({ pathname }) => {
            setPath(pathname)
        })
    }, [])

    return Done.map((val) => (
        <>
            <WhiteSpace />
            <Items path={paths} route={val} key={val.title} />
            <WhiteSpace />
            {/* <HrDotted /> */}
        </>
    ))
}


/////// router dome ///////
function OpenRouter() {
    const {open} = useRouterDrawer()
    return (
        <RBlock onClick={open} >
            <Icon style={{color: 'rgb(82 180 183)'}} type="icon-drag"/>
        </RBlock>
    )
}

function CloseRouter() {
    const {close} = useRouterDrawer()
    return (
        <CloseBlock onClick={close} src="./gameUI/close.png" />
    )
}

const RBlock = styled.div`
    padding: 4px 0px 4px 6px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: ${p => p.theme.color.brown};
`

const CloseBlock = styled.img`
    padding: 0;
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: calc(100% - 52px);
    width: 36px;
`



/////// style ///////
const useStyles = makeStyles({
    paper: {
    //   borderRadius: 24,
        background: 'rgb(0 0 0 / 67%)',
        maxWidth: '1080px',
        margin: 'auto'
      // width: 'calc(100% - 32px) !important'
    },
    paperFullWidth : {
        maxWidth: "calc(100% - 24px) !important",
        width: "calc(100% - 24px)",
        margin: "6px !important",
        boxShadow: "0px 0px 20px rgba(0,0,0,.9)"
    }
});

const contextInit = {
    opened: false,
    close: () => {},
    open: () => {}
}
export const Context = createContext(contextInit)

// function Recommender() {
//     const rel = useRelationData()
//     return (
//         <div
//             style={{
//                 width: '80%',
//                 padding: '6px',
//                 textAlign: 'center',
//                 marginLeft: '12%'
//             }}
//         >
//             <TextSM color='1'>Inviter: {shortAddress(rel.referrer,6)}</TextSM>
//             {/* <WhiteSpace /> */}
//             {/* <Button size='0' onClick={rel.copyReferrer}>{shortAddress(rel.referrer,6)} <Icon size='12' style={{marginLeft:'4px'}} type='icon-icon-fuzhidao'/></Button> */}
//         </div>
//     )
// }

function RouterDrawerProvider({children}) {
    const classes = useStyles()
    const [opened,setOpen] = useState(false)
    const close =  () => setOpen(false)
    return (
        <Context.Provider
            value={{
                opened,
                close,
                open: () => setOpen(true)
            }}
        >
            <FilterBlock opened={opened}>
                {children}
            </FilterBlock>
            <Drawer
                classes={classes}
                anchor='right'
                onClose={close}
                open={opened}
            >
                <RouterListBlock>
                    {/* <CloseRouter />
                    <HrDotted /> */}
                    <WhiteSpace size='1'/>
                    <FlexBlock
                        flex
                        justify='center'
                        style={{
                            padding: '8px 0px 0px 26px'
                        }}
                    >
                        <Logo size='3' style={{marginRight: '6px'}} /><TextM>{process.env.TITLE}</TextM>
                    </FlexBlock>
                    <WhiteSpace />
                    <RouterList />
                    {/* <HrDotted /> */}
                    {
                        Dev.length > 0 &&
                        <>
                            <WhiteSpace size='3'/>
                            <TextM_After align='center' l style={{color:'#92929e'}}>Coming Soon</TextM_After>
                            <WhiteSpace size='3'/>
                            <ComingSoonPage />
                        </>
                    }
                    <WhiteSpace />
                    {/* <Recommender />
                    <WhiteSpace /> */}
                    {/* <Hr />
                    <WhiteSpace /> */}
                    {/* <Footer />
                    <WhiteSpace /> */}
                </RouterListBlock>
            </Drawer>
        </Context.Provider>
    )
}

function useRouterDrawer() {
    return useContext(Context)
}


export {
    OpenRouter,
    RouterDrawerProvider,
    useRouterDrawer
}

const FilterBlock = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh;
    filter: ${p => p.opened?'blur(5px)':'none'};
    &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${p => p.theme.bodyBack};

        background-size: 100% 100%;
        z-index: -1;
    };
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(./background/back.png);
        z-index: 0;
        background-repeat: no-repeat;
        opacity: 0.7;
        background-position: bottom;
        background-size: 100% 100%;
        /* background-size: 100% 100%; */
    }
`

const RouterListBlock = styled.div`
    /* background: rgba(0,0,0,.6); */
    position: relative;
    width: 240px;
    height: inherit;
`

//////// router list ////////

export const ComingSoonPage = () => {
    return Dev.map((val) => (
        <>
            <WhiteSpace />
            <Items path={'/coming'} route={val} key={val.title} />
            <WhiteSpace />
            {/* <HrDotted /> */}
        </>
    ))
}

export const RouterPage = ({head, footer}) => {
    return (
        <Container align='left'>
            {head}
            {
                RouterFlat.map(v => <Route key={v.title} {...v}/>)
            }
            {footer}
        </Container>
    )
}


function Items({ route, path, style }) {
    const [open, setOpen] = useState(false)
    const Item = ({ val, style }) => (
        <ListItem button key={val.title} style={{
            textAlign: 'center',
            ...style
        }}>
            {/* <ListItemIcon>
                <Icon size='22' style={{color:'#fff',opacity: (path === val.path ? 1 : 0.68 )}} type={val.icon} />
            </ListItemIcon> */}
            <ListItemText
                style={{
                    marginLeft: '16px'
                }}
                primary={
                    <TextM
                        color={0}
                        style={{opacity: (path === val.path ? 1 : 0.6 )}}
                    >{val.title}</TextM>
                }
            />

            {val.open !== undefined ? <Icon size='14' type={open ? 'icon-changyong_shangshou' : 'icon-changyong_xiala'} /> : null}
        </ListItem>
    );
    if (route.child) {
        return (
            <Accordion
                square
                expanded={open}
                onChange={(_, n) => {
                    // console.log(n,'n')
                    setOpen(n)
                }}
                // style={style}
                title={<Item val={{ ...route, open }} />}
                list={route.child.map((v, i) => <Items style={{marginLeft:'8px'}}  path={path} route={v} key={i} />)}
            />
        )
    } else if (route.href) {
        return <a
            style={{
            width: '100%',
            pointerEvents:  route.disabled ? 'none' :  'all'
        }} rel="noreferrer" href={route.href} target="_blank"><Item val={route} /></a>
    } else {
        return (
            <LinkStyled
                to={route.path}
                active={path === route.path}
                key={route.title}
                style={{
                    width: '100%',
                    pointerEvents:  route.disabled ? 'none' :  'all'
                }}
            >
                <Item val={route} />
            </LinkStyled>
        )
    }
}

const LinkStyled = styled(Link)`
    display: block;
    color: ${props => props.active ? props.theme.active : 'unset'};
`


const TextM_After = styled(TextSM)`
    position: relative;
    display: block;
    font-size: 12px;
    &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 20%;
        background: rgba(255,255,255,.4);
        right: 5%;
        top: 50%;
    }
    &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 20%;
        background: rgba(255,255,255,.4);
        top: 50%;
        left: 5%;
    }
`