import { useState, useCallback } from 'react'

import {
    useToast
} from '../../hook'
import { useTranslation } from 'react-i18next';

import { Button } from '../../components'

import { BN, MAX_UINT, ERC20, useWeb3, SendOn, initWeb3, SendLocalOn } from '../../web3'
import { ZERO_ADDRESS } from 'web3-sam/dist/config';

// coins [address, decimal, name, numStr,isClear]
function useApprove(coins, senderAddress, confirmationCall, errorCall, needApprove, before, fastSign) {
    const { t, i18n } = useTranslation();
    const { account } = useWeb3()

    const { open } = useToast()

    const [button, setButton] = useState({
        loading: false,
        children: null
    })

    const onClick = async () => {
       
        if (!before()) return
        let nonce;
        /// 所有授权完成后 等待最后一笔交易 confirm 再执行 callback
        let awaitFun = new Promise((r) => {r()})

        const web3 = initWeb3()
        nonce = (await web3.eth.getTransactionCount(account)) * 1 

        // console.log(nonce,"approve nonce")
        let index = 0
        if (needApprove) {
            while (coins[index]) {
                const [tokenAddress, decimals, name, numStr, isMax, needClear] = coins[index]
                // console.log({tokenAddress, decimals, name, numStr})
                index++
                if ( tokenAddress === ZERO_ADDRESS ) continue
                if (numStr === '' || numStr * 1 === 0) continue
                // init data
                const numWei = BN(numStr).times(10 ** decimals).decimalPlaces(0)
                const erc20 = ERC20(tokenAddress)
                const owner = account
                // 检查授权额度
                const allowance = await erc20.methods.allowance(owner, senderAddress).call()
                // 无需授权
                if (BN(numWei).lte(allowance)) {
                    continue
                }
                ///////// 流程修改，统一使用手动设置 nonce 上链 ///////// 
                // 需要授权
                // 需要取消授权
                if (needClear === true) {
                    setButton({
                        loading: true,
                        children: t('Reset Approve') + name
                    })
                    
                    // 快速签名无需等待
                    const op = fastSign ? {nonce} : {}
                    nonce += 1;
                    const {
                        conf: confirmation,
                        getHash: [err]
                    } = await SendLocalOn(
                        erc20.methods.approve(senderAddress, '0'),
                        {
                            cancel() {
                                open(t('Approve Concel'), 'error')
                                setButton({
                                    loading: false,
                                    children: t('Approve')
                                }) 
                            },
                            fail() {
                                open(t('Concel Approve Fail') + ' ' + name, 'error')
                            },
                            confirm () {
                                open(name + ' ' + t('Approve Success'))
                            },
                            cont: "Concel Approve "+name,
                            ...op,
                            
                        }
                    )
                    /// 交易取消 停止循环
                    if (err) return index = -1;

                    awaitFun = confirmation                    
                    
                }
                // 重新授权
                setButton({
                    loading: true,
                    children: t('Approve ') + name
                })
                // 判断是否无限授权
                const op = fastSign ? {nonce} : {}
                console.log(senderAddress, "senderAddress")
                nonce += 1;
                const {
                    conf: confirmation,
                    getHash: [err]
                } = await SendLocalOn(
                    erc20.methods.approve(senderAddress, isMax === true ? MAX_UINT : numWei.toString(10)),
                    {
                        cancel() {
                            open(t('Approve Concel'), 'error')
                            setButton({
                                loading: false,
                                children: t('Approve')
                            }) 
                        },
                        fail() {
                            open(t('Approve Fail') + ' ' + name, 'error')
                        },
                        confirm () {
                            open(name + ' ' + t('Approve Success'))
                        },
                        cont: `Approve ${name} ${isMax? "∞" : Math.floor(numStr * 10000) / 10000 } to ${senderAddress.slice(0,8) + '...'}`,
                        ...op
                    }
                )

                /// 交易取消 停止循环
                if (err) return index = -1;
                awaitFun = confirmation                  
            }
        }
        if (index === -1) {
            errorCall()
            setButton({
                loading: false,
                children: null
            })
        } else {
            await awaitFun;
            setButton({
                loading: false,
                children: null
            })
            confirmationCall(nonce++)
        }
    }

    return {
        onClick,
        button
    }
}
// fastSign 快速签名
function Approve({fastSign = false, loading, approve = true, children, sender, coins = [], then = () => { }, error = () => { }, before = () => true, ...other }) {
    const { button, onClick } = useApprove(coins, sender, then, error, approve, before, fastSign)
    // console.log('Approve')
    return (
        <Button
            loading={loading || button.loading}
            onClick={onClick}
            {...other}
        >
            {
                button.loading === true ?
                    button.children : children
            }
        </Button>
    )
}

export default Approve