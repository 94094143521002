
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    Text,
    TextMD,
    TextM,
    TextSM,
    ButtonA,
    WhiteSpace,
    // Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg,
    ChooseButtons
} from '../../components'

// import SvgCircle from '../../pageComponents/SvgCircle'

import Swap from '../Swap'
import Liquidity from '../Swap/Liquidity'


import { useInput } from '../../hook';

// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"


// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

// const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)

function SwapChoose({value = "Trade", onChange = () => {}}) {
    const List = [
        "Trade",
        "Liquidity",
        "Bridge"
    ]
    return (
        <Container align="left">
            {
                List.map(v => (
                    <Text
                        key={v}
                        style={{marginRight: "12px"}} onClick={() => onChange(v)} size={value === v ? "2" : "1"}
                        color={value === v ? "0" : "2"}
                    >{v}</Text>
                ))
            }
        </Container>
    )
}

const PAGE = {
    Trade: Swap,
    Liquidity: Liquidity

}
function Home() {
    // const cType = useInput("Liquidity")
    const cType = useInput("Trade")
    const Page = PAGE[cType.value]
    return (
        <div style={{maxWidth: "750px", margin: "auto"}}>
            <SwapChoose
                value={cType.value}
                onChange={(target) => {
                    console.log(target)
                    if ( target === "Bridge" ) {
                        const location = window.location
                        
                        if (location.hostname.split(".")[0] !== 'coin') {
                            // location.href = `https://bridge.1inch.exchange/`
                            // 替换二级域名 为 bridge
                            const hs = location.hostname.split(".")
                            let newHostname;
                            if (hs.length <= 1) {
                                newHostname = "coin." + location.hostname
                            }
                            else {
                                newHostname = location.hostname.replace(hs[0], "coin")
                            }
                            location.hostname = newHostname
                        }
                    } else cType.onChange(target)
                }}
            />
            {
                // cType.value === "Trade" ? <Swap /> : <Liquidity />
            }
            <Page />
        </div>
        
    )
}
export default Home
