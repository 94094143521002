import ethers, { Signer } from 'ethers'
import IMultiCall from './IMultiCall.json'
import IErc20 from './IErc20.json'


const CreatNumbrAddress = n =>"0x" + n.toString(16).padStart(40, "0")
const ZERO_ADDRESS = CreatNumbrAddress(0)
const DEFAULT_ACCOUNT = "0xA4BE0c823E1026AF948F9E278377381158e54Dc9"
const DEFAULT_CHAIN_ID = 31337
const MAX256 = "0x" + "f".repeat(64)


let _default = {
    rpc: "http://127.0.0.1:8545",
    chainId: DEFAULT_CHAIN_ID,
    from: DEFAULT_ACCOUNT
}

function proxy(obj, key, call) {
    Object.defineProperty(obj, key, {
        get() {
            return call(this)
        },
        enumerable : true,
        configurable : false
    })
}

/**
 * @param {String} url 
 * @returns {JsonRpcProvider}
 */
function CreateProviderForRpc(url) {
    return new ethers.providers.JsonRpcProvider(url)
}

let _defaultProvider = null

/**
 * @title set rpc default config
 * @param {Object} config
 * @param {String} config.rpc
 * @param {String} config.chainId
 * @param {String} config.from
 * @returns {void}
 */
function SetDefault({rpc, chainId, from = _default.from}) {
    _default = {rpc, chainId, from}
    _defaultProvider = CreateProviderForRpc(_default.rpc)
}

/**
 * @returns {Signer}
 */
function CreateSigner() {
    let provider;
    let signer;
    if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum)
        signer = provider.getSigner()
    }
    else if ( window.web3 ) {
        provider = new ethers.providers.Web3Provider(window.web3.currentProvider)
        signer = provider.getSigner()
    }
    else {
        provider = CreateProviderForRpc(_default.rpc)
        signer = new ethers.VoidSigner(_default.from , provider) 
    }
    return signer
}

let _globalSigner = null
function GetGlobalSigner() {
    if (_globalSigner === null) {
        ResetGlobalSigner()
    }
    return _globalSigner
}
function ResetGlobalSigner() {
    _globalSigner = CreateSigner()
    return _globalSigner
}

/**
 * @param {String} address  
 * @param {Object} abi
 * @param {Object} provider
 */
ethers.Contract.prototype._calls_ = null
proxy(ethers.Contract.prototype, "calls", function(_self_) {
    if (_self_._calls_ === null) {
        const _calls_ = {}
        for(let key in _self_.functions) {
            _calls_[key] = (...args) => {
                const met = [
                    _self_.address,
                    _self_.interface.encodeFunctionData(key, args)
                ]
                met._isMethods = true
                met.decode = hex => {
                    const ed = _self_.interface.decodeFunctionResult(key, hex)
                    return ed.length <= 1 ? ed[0] : ed
                }
                return met
            }
        }
        _self_._calls_ = _calls_
    }
    return _self_._calls_
})

ethers.Contract.prototype._sighash_ = null
proxy(ethers.Contract.prototype, "sighash", function(_self_) {
    if (_self_._sighash_ === null) {
        const _sighash_ = {}
        for(let key in _self_.functions) {
            const _hashKey = _self_.interface.getSighash(key)
            _sighash_[_hashKey] = {
                method: key,
                decode: hex => {
                    const ed = _self_.interface.decodeFunctionData(key, hex)
                    return ed.length <= 1 ? ed[0] : ed
                }
            }
        }
        _self_._sighash_ = _sighash_
    }
    return _self_._sighash_
})

ethers.Contract.prototype._eventTopics_ = null
proxy(ethers.Contract.prototype, "events", function(_self_) {
    if (_self_._eventTopics_ === null) {
        const _eventTopics_ = {}
        for(let key in _self_.filters) {
            const [_topics_] = _self_.interface.encodeFilterTopics(key, [])
            _eventTopics_[_topics_] = {
                event: key,
                decode: (data, topics) => {
                    return _self_.interface.decodeEventLog(key, data, topics)
                }
            }
        }
        _self_._eventTopics_ = _eventTopics_
    }
    return _self_._eventTopics_
})

function CreateContract(address, abi, provider) {
    return new ethers.Contract(address, abi, provider || _defaultProvider)
}

/**
 * @title multicall
 * @param {String} address
 * @param {Object | unll} provider
 * @returns {Object} calls
 * @returns {Function} calls.calls
 * @returns {Contract} calls.$
 */
function MultiCall(address, provider) {
    let _multiCall = CreateContract(address, IMultiCall, provider)
    const all = async (_contractCalls, _overrides = {}) => {
        // contractCalls 传入的 Array | Object
        // 接受 conract.calls 方法
        // 如果传入的是一维数组就不需要处理
        // 返回的结果
        let _result = Array.isArray(_contractCalls) ? [] : _contractCalls
        // mutil calls 的请求列队
        let _calls_ = []
        const _eachCalls = (_parentObj, _key, _contractCalls_) => {
            if ( _contractCalls_._isMethods ) {
                const _cIndex = _calls_.length
                _calls_.push(_contractCalls_)
                proxy(_parentObj, _key, () => _contractCalls_.decode(_calls_[_cIndex]))
            }
            else {
                if (typeof _contractCalls_ !== "object" || _contractCalls_ === null) throw new Error(`${_key} is not a method`)
                _parentObj[_key] = Array.isArray(_contractCalls_) ? [] : {}
                // 循环到最后一层 不是对象 则报错
                for(let k in _contractCalls_) {
                    _eachCalls(_parentObj[_key], k, _contractCalls_[k])
                }
            }
        }
        for(let k in _contractCalls) {
            _eachCalls(_result, k, _contractCalls[k])
        }
        const _c = await _multiCall.callStatic.aggregate(_calls_, _overrides)
        _calls_ = _c.returnData
        const _blockNumber = await _c.blockNumber.toString()
        proxy(_result, "_blockNumber_", () => _blockNumber)
        return _result
    }
    return {
        $: _multiCall,
        all,
    }
}

/**
 * @title token contract
 */
function Erc20(address, provider) {
    return CreateContract(address, IErc20, provider)
}

/**
 * @title big number
 */
function WeiToUnits(wei, decimal = 18) {
    return ethers.utils.formatUnits(wei, decimal)
}
function EtherToWei(units, decimal = 18) {
    if (checkStrIsNumber(units)) units = "0"
    return ethers.utils.parseUnits(units + "", decimal)
    // return "0"
}
// comma style
function EtherToInt(str) {
    return checkStrIsNumber(str) ? '0.00' :  (str*1).toLocaleString()
}
function checkStrIsNumber(str) {
    return (str === null || str === undefined || isNaN(str) || str === '0' || str === '')
}

// listen block
function OnBlock(provider, callback) {    
    let _start = true
    const start = async () => {
        let _lastBlockNumber = 0
        // await callback(0, _lastBlockNumber)
        _start = true
        while(_start) {
            try {
                const _blockNumber = await provider.getBlockNumber()
                if (_blockNumber !== _lastBlockNumber) {
                    await callback(_lastBlockNumber, _blockNumber)
                    _lastBlockNumber = _blockNumber
                }
            } catch (error) {
                // provider 发生改变时，会抛出异常
                console.log(error.messgae)
            }
            
            await new Promise(resolve => setTimeout(resolve, 1000))
        }
    }
    start()
    return {
        stop: () => {
            _start = false
            // callback = () => {}
        },
        start
    }
}

function ShortAddress( address = '', len = 4 ) {
    return address.slice(0, len) +'...'+ address.slice(address.length - len)
}

function BN(number) {
    return ethers.BigNumber.from(number)
}

// tokens 
// [
//     {token: [address, decimal, symbol, name], allowanceAmount, needClear, sender, isMax},
// ]
/**
 * @title Approve tokens
 * @dev 该方法只能在钱包中使用，默认 signer 为钱包提供的 signer
 * @param {Array<Token>} tokens
 * @param {Token} Token[]
 * @param {Array} Token.token
 * @param {string} Token.token[0] address
 * @param {number} Token.token[1] decimal
 * @param {string} Token.token[2] symbol
 * @param {string} Token.token[3] name
 * @param {BigNumber | string | number} tokens.amount // 授权金额
 * @param {BigNumber | string } tokens.amountWei
 * @param {boolean} tokens.needClear // 是否需要在授权余额不足时先清除授权，默认为 false
 * @param {boolean} tokens.isMax // 最大授权，默认为 false
 * @param {string} tokens.sender // 授权地址，默认为当前钱包地址
 * @param {Provider} provider
 * @returns {Promise<>}
 */
async function Approves(tokens, {provider, callback = () => {}}) {
    let [
        nonce,
        _owner
    ] = await Promise.all([
        provider.getTransactionCount(),
        provider.getAddress()
    ])
    let tx = {
        wait: () => {}
    };
    for(let i = 0; i < tokens.length; i++) {
        const _tokenSet = tokens[i]
        const [address, decimal,] = _tokenSet.token
        
        const _senderAddress = _tokenSet.sender
        const _needClear = _tokenSet.needClear
        const _allowanceAmount = _tokenSet.amount
        const _erc20 = Erc20(address, provider)
        const _allowanceWei = _tokenSet.amountWei || BN(_allowanceAmount).mul(BN(10).pow(decimal))
        // 需要检查授权余额，不足并且需要清除授权时，先清除授权
        const _allowance = await _erc20.allowance(_owner, _senderAddress)
        if ( _needClear === true ) {    
            if ( _allowance.lt(_allowanceWei) && _allowance.gt(0) ) {
                try {
                    tx = await _erc20.approve(_senderAddress, 0, {nonce})
                    nonce++
                    await callback({
                        tx,
                        status: 'clear allowance',
                        token: _tokenSet.token
                    }) 
                } catch (error) {
                    // 取消签名
                    await callback({
                        status: 'clear allowance',
                        token: _tokenSet.token,
                        error: error.message
                    })
                    return {
                        nonce,
                        tx,
                        error: error.message
                    }
                }
            }
        }

        if ( _allowance.lt(_allowanceWei) ) {
            const _isMax = _tokenSet.isMax
            const _approveAmount = _isMax === true ? MAX256 : _allowanceWei
            try {
                tx = await _erc20.approve(_senderAddress, _approveAmount, {nonce})
                nonce++
                await callback({
                    tx,
                    status: 'approve',
                    token: _tokenSet.token

                })
            } catch (error) {
                await callback({
                    status: 'approve',
                    token: _tokenSet.token,
                    error: error.message
                })
                return {
                    nonce,
                    tx,
                    error: error.message
                }
            }
        }
    }
    return {
        nonce,
        tx
    }
}

/**
 * @title switch network
 * @param {hex | number} chainId
 * @param {string} chainName
 * @param {object} nativeCurrency {decimal, symbol, name}
 * @param {string} rpcUrls
 * @param {string} blockExplorerUrls
 * @returns {Promise<su>}
 */
function SwitchChain({chainId, chainName, nativeCurrency, rpcUrls, blockExplorerUrls }) {
    const _provider = GetGlobalSigner().provider.provider
    const customChainParams = {
        chainId, // 以太坊主网的 chainId
        chainName, // 自定义网络的名称
        nativeCurrency,
        rpcUrls: [rpcUrls], // 自定义 RPC 节点的 URL
        blockExplorerUrls: [blockExplorerUrls], // 自定义区块浏览器的 URL
    };
    return _provider.request({
        method: 'wallet_addEthereumChain',
        params: [customChainParams],
    })
}

/**
 * @title add token
 * @param {array} token
 * @param {string} token[0] address
 * @param {number} token[1] decimal
 * @param {string} token[2] symbol
 * @param {string} token[3] name
 * @returns {Promise<>}
 */
function AddToken([address, decimals, symbol, image]) {
    const _provider = GetGlobalSigner().provider.provider
    return _provider.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20',
            options: {
                address,
                symbol,
                decimals,
                image,
            },
        },
    })
}

// // tokens 
// // [
// //     [address, decimal, symbol, name, allowanceAmount, {needClear, sender, isMax, provider}],
// // ]
// /**
//  * @title Approve tokens
//  * @dev 该方法只能在钱包中使用，默认 signer 为钱包提供的 signer
//  * @param {Array<Token>} tokens
//  * @param {Token} tokens[]
//  * @param {string} tokens[0].address
//  * @param {number} tokens[1].decimal
//  * @param {string} tokens[2].symbol
//  * @param {string} tokens[3].name
//  * @param {BigNumber | string | number} tokens[4].allowanceAmount // 授权金额
//  * @param {boolean} tokens[5].needClear // 是否需要在授权余额不足时先清除授权，默认为 false
//  * @param {boolean} tokens[5].isMax // 最大授权，默认为 false
//  * @param {string} tokens[5].sender // 授权地址，默认为当前钱包地址
//  * @param {Provider} tokens[5].provider
//  * @param {Provider} signer
//  * @returns {Promise<>}
//  */
// async function SignApproves(tokens, {signer, progressCallback = () => {}}) {
//     const signerAddress = signer.provider.provider.selectedAddress
//     for(let i = 0; i < tokens.length; i++) {
//         const _token = tokens[i]
//         const [address, decimal,,, allowanceAmount] = _token

//         const _tokenSet = _token[5]
//         const _senderAddress = _tokenSet.sender
//         const _needClear = _tokenSet.needClear
//         const _provider = _tokenSet.provider
//         const _erc20 = Erc20(address, _provider)
//         console.log(
//             await _erc20.populateTransaction.approve(_senderAddress, 0),
//             "(_senderAddress, 0)"
//         )
//         const _allowanceWei = BN(allowanceAmount).mul(BN(10).pow(decimal))
//         // 需要检查授权余额，不足并且需要清除授权时，先清除授权
//         let nonce = await _provider.getTransactionCount( signerAddress )

//         const _allowance = await _erc20.allowance(_senderAddress, _erc20.address)
        
//         if ( _needClear === true ) {    
//             if ( _allowance.lt(_allowanceWei) ) {
                
//                 const [to,data] = _erc20.calls.approve(_senderAddress, 0)
//                 console.log("data", data)
//                 const signed = await SignTransction({signer, to, data, nonce, provider: _provider})
//                 if (signed.error) {
//                     await progressCallback({
//                         error: signed.error,
//                         status: 'clear allowance',
//                         token: _token
//                     }) 
//                     return
//                 }
//                 nonce++
//                 await progressCallback({
//                     tx: signed,
//                     status: 'clear allowance',
//                     token: _token
//                 }) 
//             }
//         }

//         if ( _allowance.lt(_allowanceWei) ) {
//             const _isMax = _tokenSet.isMax
//             const _approveAmount = _isMax === true ? MAX256 : _allowanceWei
//             const [to,data] = _erc20.calls.approve(_senderAddress, _approveAmount)
//             console.log("approve data", data)

//             const signed = await SignTransction({signer, to, data, nonce, provider: _provider})
//             if (signed.error) {
//                 await progressCallback({
//                     error: signed.error,
//                     status: 'approve',
//                     token: _token
//                 }) 
//                 return
//             }
//             nonce++
//             await progressCallback({
//                 tx: signed,
//                 status: 'approve',
//                 token: _token
//             })
//         }
//     }
// }

// async function SignTransction({signer, to, value = "0x00", data, nonce, provider}) {
//     const _provider = provider || _defaultProvider
//     const from = signer.provider.provider.selectedAddress
//     console.log(from)
//     const _send = [
//         _provider.getGasPrice(),
//         _provider.estimateGas({
//             from,
//             to,
//             value,
//             data
//         })
//     ]
//     if (!nonce) {
//         _send.push(_provider.getTransactionCount(from))
//     }
//     const estimate = await Promise.all(_send)
//     nonce = nonce || estimate[2]
//     // const {
//     //     gasPrice,
//     //     maxFeePerGas,
//     //     maxPriorityFeePerGas
//     // } = estimate[0]

//     const gasPrice = estimate[0]

//     const gasLimit = estimate[1]
//     const {chainId} = await _provider.getNetwork()
//     console.log(chainId, _provider)
//     try {
//         const transaction = {
//             to,
//             value,
//             data,
//             nonce,
//             gasPrice,
//             gasLimit,
//             chainId
//         }
        
//         // 0xf86b1a850a7a35820082b600943c1b3bf37a2cb0707b28beaaa19fd6c5f05614c280b844095ea7b300000000000000000000000010d0cf08764bcdcb530f6986ca690bc74676ea20ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff82032d8080
//         const signature = await signer.signMessage(ethers.utils.keccak256(ethers.utils.serializeTransaction(transaction)))
//         const { r, s, v } = ethers.utils.splitSignature(signature);
//         const address = ethers.utils.recoverAddress(ethers.utils.keccak256(ethers.utils.serializeTransaction(transaction)), {
//             r, s, v 
//           });
          
//           // 从公钥中获取签名者地址
//           const signerAddress = ethers.utils.computeAddress(publicKey);
//           console.log(
//             signerAddress, " signerAddress"
//           )
//         return _provider.sendTransaction(ethers.utils.serializeTransaction(transaction, signature))
//     } catch (error) {
//         return {
//             error: error.message
//         }
//     }
// }

/**
 * @param {*} big 
 */
const BigToString = big => {
    if (!big) return big
    else if (typeof big === "string" || typeof big === "number") return big
    else if (big instanceof ethers.BigNumber) return big.toString()
    else {
        if (typeof big === "object") {
            let _big = big instanceof Array ? [] : {}
            for(let k in big) {
                _big[k] = BigToString(big[k])
            }
            return _big
        }
        else return big
    }
};

export {
    ShortAddress,
    OnBlock,
    WeiToUnits,
    EtherToWei,
    EtherToInt,
    Erc20,
    MultiCall,
    CreateProviderForRpc,
    CreateSigner,
    SetDefault,
    GetGlobalSigner,
    ResetGlobalSigner,
    CreateContract,
    MAX256,
    ZERO_ADDRESS,
    CreatNumbrAddress,
    DEFAULT_ACCOUNT,
    DEFAULT_CHAIN_ID,
    BN,
    Approves,
    SwitchChain,
    AddToken,
    BigToString
    // SignApproves,
    // SignTransction
}