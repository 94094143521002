import { SendLocalOn} from '../../web3'
import useSendButton from "../useSendButton"

export default function useButton(
    buttonText,
    {
        send = () => {},
        cancelText,
        doneText,
        seed = 2,
        options = {},
        approve // {coins:[], spender: ''}
    } = {}
) {
    const {
        button,
        loading,
        init,
        txError,
        fail,
        successful
    } = useSendButton(buttonText)

    const funCallBack = () => {
        // 签名
        loading("Pending")
        SendLocalOn(
            send(),
            {
                seed,
                signDone: init,
                cancel() {
                    fail((cancelText || buttonText) + ' Cancel')
                },
                fail(err) {
                    // console.log(JSON.parse(err.message),'err')
                    txError(err.message)
                },
                confirm () {
                    successful((doneText || buttonText ) + ' successful')
                },
                ...options
            }
        )
    }

    if (approve) {
        return {
            coins: approve.coins,
            sender: approve.sender,
            then: funCallBack,
            loading: button.loading,
            children: button.children,
        }
    } else {
        return {
            ...button,
            onClick: funCallBack
        }
    }
}