import {memo} from 'react'

import {
    HashRouter as Router,
    Route
} from "react-router-dom";

import {
    RouterFlat,
    Done,
    INVISIBLE,
    RouterDrawerProvider
} from './router'

import AppBar from './AppBar'
import AppBarV2 from './AppBarV2'

import InitConstants, {NoReload} from './InitConstants'
import DialogProvider from '../context/Dialog'
import DialogProvider1 from '../context/Dialog1'

import DrawerProvider from '../context/DrawerProvider'
// import UserProvider from '../context/UserProvider'
import WaitMiner, {END, NOW} from './WaitMiner'

import Bridge from './Bridge';

// export const END = ~~(new Date('2021.11.15 23:59:59') / 1000 + 10)
// export const NOW = () => ~~(new Date() / 1000)

function Index( ) {
    return (
        <Router>
            <InitConstants>
                <DrawerProvider>
                    <DialogProvider1>
                        <DialogProvider>
                            <RouterDrawerProvider>
                                {
                                    NOW() <= END?
                                    <WaitMiner />:
                                    <AppBar>
                                        {[...Done,...INVISIBLE].map(v => <Route key={v.title} {...v}/>)}
                                    </AppBar>
                                }
                                {/* {Done.map(v => <Route key={v.title} {...v}/>)} */}
                            </RouterDrawerProvider>
                        </DialogProvider>
                    </DialogProvider1>
                </DrawerProvider>
            </InitConstants>
        </Router>
    )
}

function BridgeIndex() {
    return (
        <Router>
            <NoReload>
                <DrawerProvider>
                    <DialogProvider1>
                        <DialogProvider>
                            <RouterDrawerProvider>
                            <AppBarV2>
                                <Bridge />
                            </AppBarV2>
                            </RouterDrawerProvider>
                        </DialogProvider>
                    </DialogProvider1>
                </DrawerProvider>
            </NoReload>
        </Router>
        
    )
}
const isBrige = window.location.hostname.split(".")[0] === 'coin'
export default memo(isBrige ? BridgeIndex : Index )