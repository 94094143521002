import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    ChooseList,
    LSwap,
    WEthAddr
} from "../../contract/swap"

import {
    useWeb3,
    BN,
    multiCalls,
    multiCallArr,
    ZERO_ADDRESS,
    ERC20
} from '../../web3'

import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
// import useSendButton from "../useSendButton"

// import {getUrlParams, debounce} from "../../utils"
// import {getAmountOut} from "../../utils/swap"

// choose local
function getLocal(account, chainId) {
    const local = localStorage.getItem(`choose_${chainId}_${account}`)
    if (local) {
        return JSON.parse(local)
    }
    return []
}
function setLocal(account, chainId, list) {
    localStorage.setItem(`choose_${chainId}_${account}`, JSON.stringify(list))
}
function addTokenToLocal(account, chainId, token) {
    if ( !token.address ) return
    const list = getLocal(account, chainId)
    const index = list.findIndex(v => v[0] === token.address)
    if (index === -1) {
        list.push([token.address, token.decimal, token.symbol, token.name])
        setLocal(account, chainId, list)
    }
}
function removeTokenToLocal(account, chainId, tokenAddress) {
    const list = getLocal(account, chainId)
    const index = list.findIndex(v => v[0] === tokenAddress)
    if (index !== -1) {
        list.splice(index, 1)
        setLocal(account, chainId, list)
    }
}

// const BALANCE = {
//     name: "--",
//     symbol: "--",
//     decimal: 0,
//     address: "0x",
//     balance: 0,
// }
const INIT_CHOOSE = {
    list: [
        {
            name: "--",
            symbol: "--",
            decimal: 18,
            address: ZERO_ADDRESS,
            balance: 0,
            local: false
        }
    ],
    // searchToken: () => {},
    // addToken: () => {}
}

async function getChooseList(account, chainId) {
    const lSwap = LSwap()
    let chooseList = ChooseList()
    const chooseListSize = chooseList.length
    const localList = getLocal(account, chainId)
    chooseList = [...chooseList, ...localList]
    const calls = await multiCallArr(
        chooseList.map(v => lSwap.contract.methods.getBalance(v[0], account))
    )
    const list = []
    calls.forEach((v, i) => {
        const [address, decimal, symbol, name] = chooseList[i]
        const balance = BN(v).div(BN(10).pow(decimal)).toString(10)
        list.push({
            address,
            decimal,
            symbol,
            name,
            balance,
            local: chooseListSize <= i
        })
    })
    return {
        list
    }
}

const INIT_TOKEN = {
    name: null,
    symbol: null,
    decimal: null,
    address: null,
    balance: null,
}
export function useSearchToken() {
    const {account, chainId} = useWeb3()
    const tokenInput = useInput('')
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(INIT_TOKEN)
    const mMeer = WEthAddr()

    const padding = () => setLoading(true)
    const endLoading = () => setLoading(false)
    const searchToken = async () => {
        if ( mMeer[0].toLocaleLowerCase() === tokenInput.value.toLocaleLowerCase() ) {
            setToken(INIT_TOKEN)
            return
        }
        padding()
        const erc20 = ERC20(tokenInput.value)
        try {
            const calls = await multiCalls({
                name: erc20.methods.name(),
                symbol: erc20.methods.symbol(),
                decimal: erc20.methods.decimals(),
                balance: erc20.methods.balanceOf(account),
            })
            setToken({
                ...calls,
                address: tokenInput.value,
                balance: BN(calls.balance).div(BN(10).pow(calls.decimal)).toString(10)
            })
        } catch (error) {
        //    console.log(error) 
            setToken({
                ...INIT_TOKEN,
                address: false
            })
        }
        endLoading()
    }

    const initToken = () => setToken(INIT_TOKEN)
    const addToken = () => {
        addTokenToLocal(account, chainId, token)
    }
    return {
        loading,
        tokenInput,
        token,
        initToken,
        addToken,
        searchToken
    }
}

export function useChooseBalance() {
    const {account, getBlockNumber, chainId} = useWeb3()
    const [data, setData] = useState(INIT_CHOOSE)
    const blockNubmer = getBlockNumber()
    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getChooseList(account, chainId), setData)
    },[account, blockNubmer, chainId])

    const removeToken = (tokenAddress) => {
        removeTokenToLocal(account, chainId, tokenAddress)
        setData({
            ...data,
            list: data.list.filter(v => v.address !== tokenAddress)
        })
    }

    return {
        ...data,
        removeToken
    }
}

