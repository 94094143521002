// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    // Factory,
    // Router,
    // MPair,
    // BasePairs,
    SwapDefault,
    LSwap,
    BaseTokens
} from "../../contract/swap"

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS, ERC20, SendLocalOn} from '../../web3'

// import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useSendButton from "../useSendButton"
import useButton from "./useButton"

// import {getUrlParams, debounce} from "../../utils"
import {getAmountOut} from "../../utils/swap"

import useSlippage from '../useSlippage'

// swap 交易使用
// 1. 获取交易对
// 2. 计算价格
// 3. 订单薄【待定】


const INIT_PAIR = {
    nameMap: {},
    lpReserves: [[]],
    getOut: () => {
        return {
            bestPath: [],
            amountOut: 0
        }
    }
}

async function initPair(tokenIn, tokenOut) {
    const baseTokens = BaseTokens()

    const lSwap = LSwap()
    // base key
    const bKey = `${tokenIn[0]}-${tokenOut[0]}`
    const decimalMap = {
        [bKey] : [tokenIn[1], tokenOut[1]]
    }
    const nameMap = {
        [tokenIn[0]] : tokenIn[2],
        [tokenOut[0]] : tokenOut[2]
    }
    const lps = {
        [bKey]: lSwap.contract.methods.getPair(tokenIn[0], tokenOut[0])
    }
    baseTokens.forEach(v => {
        const [addr,,symbol] = v
        const outKey = `${addr}-${tokenOut[0]}`
        const inKey = `${tokenIn[0]}-${addr}`
        nameMap[addr] = symbol
        if ( tokenIn[0] !== addr && inKey !== bKey ) {
            lps[inKey] = lSwap.contract.methods.getPair(tokenIn[0], addr)
            decimalMap[inKey] = [tokenIn[1], v[1]]
        }
        if ( tokenOut[0] !== addr && outKey !== bKey ) {
            lps[outKey] = lSwap.contract.methods.getPair(addr, tokenOut[0])
            decimalMap[outKey] = [v[1], tokenOut[1]]
        }
    })
    
    let calls = await multiCalls(lps)
    console.log(calls)
    // console.log(lps, " lps")
    // search paths
    // 1. 没有有效路径
    // 2. 有两层路径[暂不考虑两层路径]
    // 3. 有一层路径
    const feeRate = lSwap.feeRate
    const filterPathMap = {}
    function filterPaths(tKey) {
        // const tKey = `${tokenInAddr}-${tokenOutAddr}`
        // console.log(tKey, " tKey")
        const fLp = calls[tKey];
        const [decimal0, decimal1] = decimalMap[tKey];
        if ( !fLp ) return;
        fLp.forEach((v, i) => {
            if ( v[0] !== ZERO_ADDRESS ) {
                if (!filterPathMap[tKey]) filterPathMap[tKey] = []
                const cloneV = [...v]
                cloneV[1] = BN(cloneV[1]).div(10 ** decimal0).toString(10)
                cloneV[2] = BN(cloneV[2]).div(10 ** decimal1).toString(10)
                filterPathMap[tKey].push([
                    ...cloneV,
                    lSwap.factorys[i],
                    feeRate[i]
                ])
            }
        })
    }
    for(let key in lps) {
        filterPaths(key)
    }

    // 1. 初始化时筛选出有效路径，通过 getout 实时计算最优路径
    // [[token0, token1, token2]] 
    const paths = []
    if ( filterPathMap[bKey] && filterPathMap[bKey].length > 0 ) {
        paths.push([tokenIn[0], tokenOut[0]])
    }
    // 暂时只考虑 一层路由
    baseTokens.forEach(v => {
        const [addr, ,] = v
        const tokenInAddr = tokenIn[0]
        const tokenOutAddr = tokenOut[0]
        if ( tokenInAddr !== addr && tokenOutAddr !== addr ) {
            const inKey = `${tokenIn[0]}-${addr}`
            const outKey = `${addr}-${tokenOut[0]}`
            if (filterPathMap[inKey] && filterPathMap[outKey] && filterPathMap[inKey].length > 0 && filterPathMap[outKey].length > 0 ) {
                paths.push([tokenIn[0], addr, tokenOut[0]])
            }
        }
    })

    function getMaxOut(amountIn, tokenInAddr, tokenOutAddr) {
        const tKey = `${tokenInAddr}-${tokenOutAddr}`
        const fLp = filterPathMap[tKey];
        // if ( !fLp ) return 0;
        let routes = []
        let amountOut = 0
        fLp.forEach(v => {
            const [, reserve0, reserve1,, feeRate] = v;
            const aOut = getAmountOut(amountIn, reserve0, reserve1, feeRate);
            if ( aOut * 1 > amountOut * 1 ) {
                routes = v
                amountOut = aOut
            }
        })
        return {
            routes,
            amountOut
        }
    }
    function getOut(amountIn = 0) {
        if (paths.length === 0 || amountIn * 1 === 0) return {
            bestPath: [],
            amountOut: 0
        }
        let maxOut = 0
        let bestPath = []        
        paths.forEach(path => {
            if (path.length > 1) {
                let mOut = amountIn
                let mRoutes = []
                for(let i = 0; i < path.length - 1; i++) {
                    const {
                        routes,
                        amountOut
                    } = getMaxOut(mOut, path[i], path[i + 1])
                    mOut = amountOut
                    mRoutes.push([
                        ...routes,
                        path[i + 1]
                    ])
                }
                // console.log(mOut, " mOut")
                if ( mOut * 1 > maxOut * 1 ) {
                    maxOut = mOut
                    bestPath = mRoutes
                }
            }
            else if ( path.length === 1 ) {
                const {
                    routes,
                    amountOut
                } = getMaxOut(amountIn, path[0], path[1])
                if ( amountOut * 1 > maxOut * 1 ) {
                    maxOut = amountOut
                    bestPath = [
                        ...routes,
                        path[1]
                    ]
                }
                
            }
            else {
                return false
            }
            
        })

        return {
            bestPath,
            amountOut: maxOut
        }
        
    }

    return {
        nameMap,
        lpReserves: filterPathMap,
        getOut
    }
}

/// 后去 余额
async function getBalance(account, tokenIn, tokenOut) {
    if (!tokenIn.length || !tokenOut.length) return {
        tokenIn: 0,
        tokenOut: 0
    }
    
    const lSwap = LSwap()
   
    const calls = await multiCalls({
        tokenInBalance: lSwap.contract.methods.getBalance(tokenIn[0], account),
        tokenOutBalance: lSwap.contract.methods.getBalance(tokenOut[0], account)
    })

    return {
        tokenIn: BN(calls.tokenInBalance).div(10 ** tokenIn[1]).toString(10),
        tokenOut: BN(calls.tokenOutBalance).div(10 ** tokenOut[1]).toString(10)
    }
}

// 获取 pair
// 此类 hook 作用是手动请求数据，不 State 数据
// 将 status 解藕，用于请求时的 loading 状态
// return 各类接口，方便 其他 hook 调用时获取数据
// api 接口为纯函数，不依赖 state
export function useGetPair() {
    const [pair, setPair] = useState(INIT_PAIR)
    // const [best, setBast] = useState(INIT_BAST_PATH)

    const [status, setLoading] = useState({
        loading: false,
        erre: null
    })

    const success = () => setLoading({loading: false, error: null})
    const fail = (error) => setLoading({loading: false, error})
    const pending = () => setLoading({loading: true, error: null})
    // 切换 token 时调用
    const getPair = async (tokenIn, tokenOut) => {
        if (!tokenIn.length || !tokenOut.length) return true
        // pending()
        try {
            const pair = await initPair(tokenIn, tokenOut)
            setPair(pair)
            success()
            return [null, pair]
        } catch (error) {
            console.log(error)
            fail(error.message)
            return [error.message, null]
        }
    }

    return {
        pair,
        getPair,
        status,
        success,
        pending,
        fail
        // best
    }
}



// 交易

// 交易记录
export function useSwap() {
    const { getBlockNumber, account } = useWeb3()

    // const [best, setBast] = useState(INIT_BAST_PATH)
    const [tBalance, setTbalance] = useState([0,0])
    const amountIn = useInput('', { type: 'number' })

    const [tokens, setTokens] = useState(() => {
        const {from: tokenIn, to: tokenOut} = SwapDefault();
        return {
            tokenIn,
            tokenOut,
            best: INIT_PAIR
        }
    })

    const best = tokens.best

    /// 整理交易
    const { getPair, status, pending, success, fail: getFail } = useGetPair()
    const { slippage: { value: slippage }, timeScends } = useSlippage()

    const {
        button,
        // setButtonText,
        loading,
        init: initButton,
        txError,
        fail,
        successful
    } = useSendButton('Swap')
   
    const {
        tokenIn,
        tokenOut,
    } = useMemo(() => {
        let tokenIn = tokens.tokenIn
        let tokenOut = tokens.tokenOut
        return {
            tokenIn,
            tokenOut
        }
    }, [tokens.tokenIn[0], tokens.tokenOut[0]])

    const {
        tokenInBalance,
        tokenOutBalance
    } = useMemo(() => {
        let tokenInBalance = tBalance[0]
        let tokenOutBalance = tBalance[1]
        return {
            tokenInBalance,
            tokenOutBalance
        }
    }, [tBalance[0], tBalance[1]])

    
    // 设置token
    const resetToken = async (tokenIn, tokenOut) => {
        pending()
        // if ( tokens.tokenIn[0] !== tokenIn[0] || tokens.tokenOut[0] !== tokenOut[0] ) {
        const [err, pair] = await getPair(tokenIn, tokenOut)
        if (err) return getFail(err)
        setTokens({
            tokenIn,
            tokenOut,
            best: pair
        })
        // }
        success()
    }

    // const bestRes = async () =>

    const blockNubmer = getBlockNumber()
    // set token lp
    useEffect(() => {
        resetToken(tokens.tokenIn, tokens.tokenOut)
    }, [blockNubmer, tokens.tokenIn[0], tokens.tokenOut[0]])

    // set token balance
    useEffect(() => {
        const updateBalance = async () => {
            const { tokenIn, tokenOut } = await getBalance(account, tokens.tokenIn, tokens.tokenOut)
            setTbalance([tokenIn, tokenOut])
        }
        updateBalance()
    }, [blockNubmer, account, tokens.tokenIn[0], tokens.tokenOut[0]])

    // 切换交易方向
    const switchRes = () => {
        // setIsRes(v => !v)
        if (!status.loading) resetToken(tokens.tokenOut, tokens.tokenIn)
    }
    const {
        amountOut, 
        amountOutMin,
        bestPath,
        smartPath
    } = useMemo(() => {
        if (amountIn.value === '' ) {
            return {
                amountOut: 0,
                amountOutMin: 0,
                bestPath: [],
                smartPath: "--"
            }
        }
        const {
            bestPath,
            amountOut
        } = best.getOut(amountIn.value)
        const amountOutMin = BN(amountOut).mul(1 - slippage / 100).dp(6,1).toString(10)
        // const amountOutMin = 1
        let smartPath = [
            tokens.tokenIn[2]
        ]
        if ( bestPath.length === 0 ) {
            smartPath = "No liquidity"
        } else {
            bestPath.forEach(v => {
                smartPath.push(best.nameMap[v[5]])
            })
            smartPath = smartPath.join(' -> ')
        }

        // console.log(smartPath, " const smartPath = ")
        return {
            amountOut,
            amountOutMin,
            bestPath,
            smartPath
        }
    }, [amountIn.value, best.getOut, slippage])

    const setTokenIn = token => {
        resetToken(token, tokens.tokenOut)
    }
    const setTokenOut = token => {
        resetToken(tokens.tokenIn, token)
    }

    // console.log('bestPath', bestPath.map((v) => [v[0], v[5], v[4] * 1e4]))
    const exChange = async () => {
        loading("Pending...")
        const lSwap = LSwap()
        const amountInWei = BN(amountIn.value).mul(10 ** tokenIn[1]).dp(0,1).toString(10)
        const amountOutWei = BN(amountOutMin).mul(10 ** tokenOut[1]).sub(1).dp(0,1).toFixed(0)
        // const amountOutWei = '0'
        const swapRoute = bestPath.map((v) => [v[0], v[5], v[4] * 1e4])
        const options = {}
        if ( tokenIn[0] === ZERO_ADDRESS ) options.value = amountInWei
        // console.log('swapRoute', swapRoute)
        SendLocalOn(
            lSwap.contract.methods.swap(
                tokenIn[0],
                amountInWei,
                swapRoute,
                amountOutWei,
                account,
                (~~(new Date() / 1000)) + timeScends.value * 60,
            ),
            {
                seed: 2,
                cont: `Swap ${Math.floor(amountIn.value * 1000) / 1000} ${tokenIn[2]} for ${tokenOut[2]}`,
                signDone: initButton,
                cancel() {
                    fail('Swap Cancel')
                },
                fail(err) {
                    // console.log(err.message.match(/INSUFFICIENT_OUTPUT_AMOUNT1/),'err.message')
                    if (err.message.match(/INSUFFICIENT_OUTPUT_AMOUNT/)) {
                        txError("Trading slippage set too small")
                    } else {
                        txError(err.message)
                    }
                    fail('Swap fail')
                },
                confirm () {
                    amountIn.onChange(0)
                    successful('Swap successful')
                },
                ...options
            }
        )
    }

    return {
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        amountOutMin,
        bestPath,
        switchRes,
        resetToken,
        slippage,
        tokenInBalance,
        tokenOutBalance,
        status,
        setTokenIn,
        setTokenOut,
        smartPath,
        approve: {
            fastSign: true,
            coins: [
                [ tokenIn[0], tokenIn[1], tokenIn[2], amountIn.value, true ],
                // [ ...tokenOut, 100, true ]
            ],
            loading: button.loading,
            children: button.children,
            then: exChange,
            sender: LSwap().contract._address,
            // disabled: !!swapErr
        }
    }
}


///////// liquidity //////////
const INIT_LIQUIDITY = {
    token0: {
        detail: [null, 0, null],
        balance: 0
    },
    token1: {
        detail: [null, 0, null],
        balance: 0
    },
    pair: {
        balance: 0,
        address: ZERO_ADDRESS,
        isCreate: false,
        reserves: [0,0],
        totalSupply: 0
    }
}


async function initPairLiquidity(_token0, _token1, _account) {
    const lSwap = LSwap()
    const calls = await multiCalls({
        token0Balance: lSwap.contract.methods.getBalance(_token0[0], _account),
        token1Balance: lSwap.contract.methods.getBalance(_token1[0], _account),
        pairs: lSwap.contract.methods.getPairOne(lSwap.factoryAddress[0], _token0[0], _token1[0], _account)
    })

    // console.log(
    //     calls, " 0xd5f12572B4d0AE1B4E3B0496BEF0Eb441fBa8059"
    // )

    const token0 = {}
    token0.detail = _token0
    token0.balance = BN(calls.token0Balance).div(10 ** _token0[1]).toString(10)
    const token1 = {}
    token1.detail = _token1
    token1.balance = BN(calls.token1Balance).div(10 ** _token1[1]).toString(10)

    // default swap id = 0 | candy swap
    const pair = {}
    pair.balance = BN(calls.pairs.balance).div(10 ** 18).toString(10)
    pair.address = calls.pairs.pair
    pair.isCreate = calls.pairs.pair !== ZERO_ADDRESS
    pair.reserves = [
        BN(calls.pairs.reserve0).div(10 ** _token0[1]).toString(10),
        BN(calls.pairs.reserve1).div(10 ** _token1[1]).toString(10)
    ]
    pair.totalSupply = BN(calls.pairs.totalSupply).div(10 ** 18).toString(10)

    return {
        token0,
        token1,
        pair
    }
}

export function useLiquidity() {
    const { account, getBlockNumber } = useWeb3()
    const [loading, setLoading] = useState(false)
    const [liquidity, setLiquidity] = useState(INIT_LIQUIDITY)

    const loadingOn = () => setLoading(true)
    const loadingOff = () => setLoading(false)
    const initLp = async (token0, token1, account) => {
        loadingOn()
        setLiquidity(
            await initPairLiquidity(token0, token1, account)
        )
        loadingOff()
    }

    // useEffect(() => {
    //     const {from: token0, to: token1} = SwapDefault();
    //     initLp(token0, token1, account)
    // },[])

    useEffect(() => {
        if ( liquidity.token0.detail[0] && liquidity.token1.detail[0] ) initLp(liquidity.token0.detail, liquidity.token1.detail, account)
        else {
            const {from: token0, to: token1} = SwapDefault();
            initLp(token0, token1, account)
        }
    },[getBlockNumber(), liquidity.token0.detail[0], liquidity.token1.detail[0], account])
    

    const getTokenBalanceByLp = lpAmount => {
        const { pair } = liquidity
        const { reserves, totalSupply } = pair
        const [token0, token1] = reserves
        const amount0 = BN(token0).mul(lpAmount).div(totalSupply).toString(10)
        const amount1 = BN(token1).mul(lpAmount).div(totalSupply).toString(10)
        return [amount0, amount1]
    }

    const resetPair = (token0, token1) => initLp(token0, token1, account)

    const setToken0 = token => {
        resetPair(token, liquidity.token1.detail)
    }
    const setToken1 = token => {
        resetPair(liquidity.token0.detail, token)
    }
    return {
        initLp,
        resetPair,
        getTokenBalanceByLp,
        setToken0,
        setToken1,
        loading,
        liquidity
    }
}

// 添加流动性
export function useAddLiquidity(pair = INIT_LIQUIDITY) {
    const { account } = useWeb3()
    const amount0 = useInput('', { type: 'number' })
    const amount1 = useInput('', { type: 'number' })
    const { slippage: { value: slippage }, timeScends } = useSlippage()

    const setAmount1 = value => {
        amount0.onChange(value)
        value = value === "" ? 0 : value
        if ( pair.pair.isCreate ) {
            const _amount1 = BN(value).mul(pair.pair.reserves[1]).div(pair.pair.reserves[0]).toString(10)
            amount1.onChange(_amount1)
        }
    }

    const setAmount0 = value => {
        amount1.onChange(value)
        value = value === "" ? 0 : value
        if ( pair.pair.isCreate ) {
            const _amount0 = BN(value).mul(pair.pair.reserves[0]).div(pair.pair.reserves[1]).toString(10)
            amount0.onChange(_amount0)
        } 
    }
    
    const poolAmount = !pair.pair.isCreate ? BN(amount0.value || 0).mul(amount1.value || 0).squareRoot(2).toString(10) : BN(amount0.value || 0).mul(pair.pair.totalSupply).div(BN(pair.pair.reserves[0]).add(amount0.value || 0)).toString(10)
    const shareOfPool = !pair.pair.isCreate ? 99.9 : BN(poolAmount).mul(100).div(pair.pair.totalSupply).dp(4,1).toString(10)

    const { sender, coins, isMEER, token0IsZero } = useMemo(() => {
        const lSwap = LSwap()
        const coins = [
            [...pair.token0.detail.slice(0,-1),1000, true],
            [...pair.token1.detail.slice(0,-1),1000, true]
        ]
        const token0IsZero = pair.token0.detail[0] === ZERO_ADDRESS
        const isMEER = token0IsZero || pair.token1.detail[0] === ZERO_ADDRESS
        return {
            sender: lSwap.contract._address,
            coins,
            isMEER,
            token0IsZero
        }
    } ,[pair.token0.detail[0], pair.token1.detail[0]])
    const addLiquidityButton = useButton((pair.pair.address === ZERO_ADDRESS ? 'Create Liquidity' : 'Add Liquidity'), {
        approve: {
            sender,
            coins
        },
        options: {
            value: isMEER ?
                BN((token0IsZero ? amount0.value : amount1.value) || 0).mul(1e18).dp(0,1).toString(10)
                : 0
        },
        send() {
            const lSwap = LSwap()
            const amount0Wei = BN(amount0.value).mul(10 ** pair.token0.detail[1]).dp(0,1).toString(10)
            const amount1Wei = BN(amount1.value).mul(10 ** pair.token1.detail[1]).dp(0,1).toString(10)
            const minPair = BN(poolAmount).mul(1 - slippage / 100).div(1e18).dp(0,1).toString(10)
            // console.log(
            //     poolAmount,
            //     minPair
            // )
            if ( !isMEER ) {
                return lSwap.contract.methods.addLiquidity(
                    pair.token0.detail[0],
                    pair.token1.detail[0],
                    lSwap.factoryAddress[0],
                    amount0Wei,
                    amount1Wei,
                    minPair,
                    account,
                    (~~(new Date() / 1000)) + timeScends.value * 60
                )
            }
            else {
                const token = token0IsZero ? pair.token1.detail : pair.token0.detail
                let amountWei = token0IsZero ? amount1.value : amount0.value
                amountWei = BN(amountWei).mul(10 ** token[1]).dp(0,1).toString(10)
                console.log(token, amountWei, " token")
                return lSwap.contract.methods.addLiquidityMEER(
                    token[0],
                    lSwap.factoryAddress[1],
                    amountWei,
                    minPair,
                    account,
                    (~~(new Date() / 1000)) + timeScends.value * 60
                )
            }
        }
    })

    return {
        amount0,
        amount1,
        setAmount0,
        setAmount1,
        shareOfPool,
        addLiquidityButton
    }

}

// 移除流动性
export function useRemoveLiquidity(pair = INIT_LIQUIDITY) {
    const {account} = useWeb3()
    const lpAmount = useInput('', { type: 'number' })
    const { slippage: { value: slippage }, timeScends } = useSlippage()

    useEffect(() => {
        lpAmount.onChange(pair.pair.balance)
    },[pair.pair.balance])


    const { sender, coins} = useMemo(() => {
        const lSwap = LSwap()
        const coins = [
            [pair.pair.address, 18, "LP",1000, true]
        ]
        return {
            sender: lSwap.contract._address,
            coins
        }
    } ,[pair.pair.address])

    const getTokenBalanceByLp = lpAmount => {
        const { reserves, totalSupply } = pair.pair
        const [token0, token1] = reserves
        const amount0 = BN(token0).mul(lpAmount).div(totalSupply).toString(10)
        const amount1 = BN(token1).mul(lpAmount).div(totalSupply).toString(10)
        return [amount0, amount1]
    }

    const removeLiquidityButton = useButton('Remove', {
        approve: {sender, coins},
        send() {
            const lSwap = LSwap()
            const amountWei = BN(lpAmount.value).mul(10 ** 18).dp(0,1).toString(10)
            const [amount0, amount1] = getTokenBalanceByLp(lpAmount.value)
            const minAmount0 = BN(amount0).mul(1 - slippage / 100).dp(0,1).toString(10)
            const minAmount1 = BN(amount1).mul(1 - slippage / 100).dp(0,1).toString(10)
            // removeLiquidity
            // address _pair,
            // uint _amountPair,
            // uint _minAmount0,
            // uint _minAmount1,
            // address _to,
            // uint _deadline
            return lSwap.contract.methods.removeLiquidity(
                pair.pair.address,
                amountWei,
                minAmount0,
                minAmount1,
                account,
                (~~(new Date() / 1000)) + timeScends.value * 60
            )
        }
    })

    const [lp0, lp1] = getTokenBalanceByLp(lpAmount.value)

    return {
        lpAmount,
        removeLiquidityButton,
        lp0,
        lp1
    }

}