import IFactory from './ABI/IFactory.json'
import IRouter from './ABI/IRouter.json'
import IMPair from './ABI/IMPair.json'
import ILSwap from './ABI/ILSwap.json'

import {ERC20, newContract} from "../web3";
import {getConstants} from './constants' 

export const Factory = () => newContract(getConstants().FACTORY, IFactory)
export const Factorys = () => {
    const fs = getConstants().FACTORYS
    return fs.map(f => [newContract(f[0], IFactory), f[1]])
}
export const WEthAddr = () => getConstants().WETH
export const Router = () => newContract(getConstants().ROUTER, IRouter)
export const MPair = address => newContract(address, IMPair)

export const BasePairs = () => getConstants().BASE_PAIRS

export const SwapDefault = () => getConstants().SWAP_DEFAULT

export const LSwap = () => {
    const factoryAddress = []
    const factorys = []
    getConstants().FACTORYS.forEach(v => {
        factoryAddress.push(v[0])
        factorys.push(v[1])
    })
    return {
        factorys,
        factoryAddress,
        feeRate: [0.005, 0.005],
        contract: newContract(getConstants().LSWAP, ILSwap)
    }
}

export const BaseTokens = () => getConstants().BASE_TOKENS
export const ChooseList = () => getConstants().CHOOSE_TOKENS