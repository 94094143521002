// Relation
import { useEffect, useMemo, useState } from "react";

// import copy from 'copy-to-clipboard'

import {
    // Factory,
    // Router,
    // MPair,
    // BasePairs,
    SwapDefault,
    LSwap,
    BaseTokens
} from "../../contract/swap"

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS, ERC20, SendLocalOn} from '../../web3'

// import initAsyncData from '../initAsyncData'

import useInput from '../useInput'

import eventAbi from './eventABI.json'

let eventList;
function initABI() {
    if ( eventList ) return eventList;
    const web3 = initWeb3()
    eventList = {}
    eventAbi.forEach(v => {
        eventList[web3.eth.abi.encodeEventSignature(v)] = v
    })
    return eventList
}
initABI();

const INIT_HASH = {
    blockHash: null,
    blockNumber: null,
    blockTime: null,
    timestamp: null,
    from: null,
    to: null,
    value: 0,
    gas: "0",
    gasPrice: "0",
    fee: "0",
    log: [],
    transfer: [],
    approve: []
}

async function getHashDetail(hash) {
    const lSwap = LSwap()
    const web3 = initWeb3()
    // const hashDetail = await web3.eth.getTransactionReceipt(hash)
    // const hashDetail2 = await web3.eth.getTransaction(hash)
    const [
        hashDetail,
        hashDetail2
    ] = await Promise.all([
        web3.eth.getTransactionReceipt(hash),
        web3.eth.getTransaction(hash)
    ])

    // 没有该笔交易
    // hashDetail2 = null
    // hashDetail = null
    if (hashDetail === null) return INIT_HASH

    // 未上链
    // hashDetail = nul
    // hashDetail2.blockHash = null

    // 上链失败
    // hashDetail.status = false

    // console.log(hashDetail)
    // console.log(hashDetail2)

    const logs = []
    hashDetail.logs.forEach(v => {
        const data = v.data;
        const topics = v.topics;
        const tSignatuer = eventList[topics[0]];
        if ( tSignatuer ) {
            logs.push({
                contract: v.address,
                param: web3.eth.abi.decodeLog(
                    tSignatuer.inputs,
                    data,
                    topics.slice(1)
                ),
                name: tSignatuer.name
            })
        }
        else {
            logs.push({
                contract: v.address,
                data,
                topics
            })
        }
    })

    const eventBetweenToken = {}
    logs.forEach(v => {
        if ( v.name === "Transfer" || v.name === "TransferFrom" || v.name === "Approval" ) {
            if ( !eventBetweenToken[v.contract] ) {
                eventBetweenToken[v.contract] = {}
            }
        }
    })
    
    const [
        block,
        tokens
    ] = await Promise.all([
        web3.eth.getBlock(hashDetail.blockNumber),
        lSwap.contract.methods.getTokens(Object.keys(eventBetweenToken)).call()
    ])

    const blockHash = block.hash
    const blockNumber = block.number
    const blockTime = new Date(block.timestamp * 1000).Format("yyyy-MM-dd hh:mm:ss")
    const timestamp = block.timestamp
    const from = hashDetail.from
    const to = hashDetail.to
    const value = BN(hashDetail2.value).div(1e18).toString(10)
    const gas = hashDetail2.gas
    const gasPrice = hashDetail2.gasPrice
    const fee = BN(hashDetail2.gas).mul(hashDetail2.gasPrice).div(1e18).toString(10)
    const log = []
    const transfer = []
    const approve = []
    Object.values(eventBetweenToken).forEach((v,i) => {
        const token = tokens[i]
        v.name = token.name
        v.symbol = token.symbol
        v.decimals = token.decimals
    })
    logs.forEach(v => {
        if ( v.name === "Transfer" || v.name === "TransferFrom" ) {
            const param = v.param
            if ( param.value !== "0" ) {
                const token = eventBetweenToken[v.contract]
                const from = param.from
                const to = param.to
                const value = BN(param.value).div(10**token.decimals).toString(10)
                const tDetail = {
                    name: token.name,
                    symbol: token.symbol,
                    from,
                    to,
                    value
                }
                transfer.push(tDetail)
            }
        }
        else if ( v.name === "Approval" ) {
            const maxU256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
            const param = v.param
            const token = eventBetweenToken[v.contract]
            const aDetail = {
                name: token.name,
                symbol: token.symbol,
                owner: param.owner,
                spender: param.spender,
                value: maxU256 === param.value ? "max" : BN(param.value).div(10**token.decimals).toString(10)
            }
            approve.push(aDetail)
        }
        else {
            log.push(v)
        }
    })

    return {
        blockHash,
        blockNumber,
        blockTime,
        timestamp,
        from,
        to,
        value,
        gas,
        gasPrice,
        fee,
        log,
        transfer,
        approve
    }
}


export function useHashDetail() {
    const [loading, setLoading] = useState(false)
    const [hashDetail, setHashDetail] = useState(INIT_HASH)

    const padding = () => setLoading(true)
    const unload = () => setLoading(false)

    const getDetail = async (hash) => {
        padding()
        setHashDetail(
            await getHashDetail(hash)
        )
        unload()
    }

    // useEffect(()=>{},)

    return {
        loading,
        hashDetail,
        getDetail
    }
}